import { useEffect, useState } from 'react';

const UseEditProduct = (code) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectorLoading, setSelectorLoading] = useState(true);
    const [brandIsLoading, setBrandIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [catValues, setCatValues] = useState([]);
    const [catInputs, setCatInputs] = useState([]);
    const [brands, setBrands] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [colours, setColours] = useState([]);
    const [colours2, setColours2] = useState([]);
    const [values, setValues] = useState({
        ficha_id: code.code,
        code2: '',
        created: '',
        update: '',
        name: '',
        brand: '',
        model: '',
        height: '',
        width: '',
        lenght: '',
        weight: '',
        country: '',
        group: '',
        subgroup: '',
        detail_description: '',
        emotional_description: '',
        dynamicFields: [],
        categories: [],
        childs: [],
    });
    const [names, setNames] = useState({});
    const [errors, setErrors] = useState({
        code: '',
        name: '',
        country: '',
        detail_description: '',
        emotional_description: '',
        model: ''
    });
    useEffect(() => {
        console.log(isLoading)
        var config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            },
        }
        if(isLoading){
            setIsLoading(false);
            fetch(`https://api.gestor.mkr.cl/get_product/${code.code}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setValues({
                            ...values,
                            code2: data.ficha_code,
                            name: data.name,
                            created: data.created_date,
                            update: data.last_update,
                            brand: data.brand,
                            model: data.model,
                            height: data.height,
                            width: data.width,
                            lenght: data.lenght,
                            weight: data.weight,
                            country: data.country,
                            group: data.group,
                            subgroup: data.subgroup,
                            emotional_description: data.emotional_description,
                            detail_description: data.detail_description,
                            categories:data.categories,
                            childs:data.childs,
                            dynamicFields: data.dynamicFields,
                        });
                        var names_cfg = {
                            method: 'POST',
                            headers: {
                                Authorization: `Bearer  ${localStorage.getItem('token')}`,    
                            },
                            body: JSON.stringify({'code_names':data.code_names})
                        }
                        fetch(`https://dash.mkr.cl/json/get_name/`, names_cfg)
                        .then(response => {
                            response.json()
                            .then(hijos => {
                                setNames(hijos)
                            })                                
                        })
                        .catch(err => {
                            console.log(err)
                            setIsLoading(false);
                        })
                        
                    })
                    
                })
            .catch(err => {
                setIsLoading(true);
            })
        }
        config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(selectorLoading){
            setSelectorLoading(false);
            fetch("https://api.gestor.mkr.cl/get_to_form/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setCatValues(data.cat_array);
                        setCatInputs(data.category_fields)
                        setColours(data.colours);
                        setColours2(data.colours2);
                        setSizes(data.sizes);
                    })
                })
            .catch(err => {
                setIsLoading(true);
            })
        }
        var dsh_config = {
            method: 'GET',
            headers: {
                Authorization: `Bearer kx94p4xm8debjhskc2tmabgmpk8f5kky`,    
            }
        }
        if(brandIsLoading){
            setBrandIsLoading(false);
            fetch("https://dash.mkr.cl/internal/api/brands", dsh_config)
                .then(response => {
                    response.json()
                    .then(marcas => {
                        var marca_array = [];
                        marcas.forEach(marca => {
                            marca_array.push({
                                name: "brand",
                                label: marca['name'],
                                value: marca['name']
                            })
                        })
                        setBrands(marca_array);
                    })
                })
            .catch(err => {
                setBrandIsLoading(false);
            })
        }
    }, [code, names, values, isLoading, selectorLoading, brandIsLoading]);

    const handleSimpleInput = (e) => {
        const {name, value} = e.target;
        var err = '';
        if(name==="name") {
            if(value.length===33) {
                err = 'El largo del nombre alcanzó el límite'; 
            }           
            setErrors({
                ...errors,
                [name]: err
            })
        }
        if(name==="model") {
            if(value.length===101) {
                err = 'El largo del nombre alcanzó el límite'; 
            }
            setErrors({
                ...errors,
                [name]: err
            })
        }
        if(name==="country") {
            if(value.length===41) {
                err = 'El largo del nombre alcanzó el límite'; 
            }
            setErrors({
                ...errors,
                [name]: err
            })
        }
        if(name==="emotional_description") {
            if(value.length===3000) {
                err = 'El largo del nombre alcanzó el límite'; 
            }
            setErrors({
                ...errors,
                [name]: err
            })
        }
        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSimpleSelect = (e) => {
        setValues({
            ...values,
            [e.name]: e.value
        });
    }
    const handleAddFields = (e,name) => {
        if(name === "dynamicFields"){
            setValues({
                ...values,
                dynamicFields: [...values.dynamicFields, {name:'', value:'', old:''}]
            });
        }
        if(name === "categories"){
            setValues({
                ...values,
                categories: [...values.categories, {cat_name: '', values:[]}]
                
            });
        }
        if(name === "childs"){
            setValues({
                ...values,
                childs: [...values.childs, {full_code: '', colour: '', colour2: '', size:'', emotional_colour_name: '', sku: '', old_sku:''}]
                
            });
        }
    }
    const handleChildSelector = (e, index) => {
        const {name, value} = e;
        var group = [...values.childs];
        group[index][name] = value;
        setValues({
            ...values,
            childs: group
        });
    }
    const handleChildInput = (e, index) => {
        const {name, value} = e.target;
        var group = [...values.childs];
        group[index][name] = value;
        setValues({
            ...values,
            childs: group
        });
    }

    const handleDynamicInput = (e, index) => {
        const {name, value} = e.target;
        var group = [...values.dynamicFields];
        group[index][name] = value;
        setValues({
            ...values,
            dynamicFields: group
        });
    }
    const handleCatSelector = (e, index) => {
        var group = [...values.categories];
        group[index].cat_name = e.value;
        const cat_len = catInputs[e.value].length;
        const catValLen = group[index].values.length;
        if (cat_len < catValLen){
            group[index].values = group[index].values.slice(0,cat_len);
        }
        setValues({
            ...values,
            categories: group
        });
    }
    const handleCatInput = (e, index, forindex) => {
        var group = [...values.categories];
        group[index].values[forindex] = e.target.value;
        setValues({
            ...values,
            categories: group
        });
    }
    

    const handleSubmit = e => {
        e.preventDefault();    
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({values, user:localStorage.getItem('username')})
        }
        fetch('https://api.gestor.mkr.cl/update_product/', config)
            .then(response => {
                response.json()
                .then(data => {
                    if (data.ok === 200) { 
                        setIsOpen(true);
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })

    }
    const keepOnPage = () => {
        setIsOpen(false);
        setIsLoading(true);
    }

    const checkErrors = () => {
        const keys = Object.keys(errors);
        var has_errors = false;
        keys.forEach(key => {
            if(errors[key] !== ''){
                has_errors = true;
            }
        });
        return has_errors;

    }
    return {
        values, errors, brands, sizes, colours, colours2, names,
        catValues, catInputs, isOpen, checkErrors, keepOnPage, 
        handleSimpleInput, handleSimpleSelect, handleChildSelector, 
        handleChildInput, handleAddFields, handleDynamicInput, 
        handleCatSelector, handleCatInput, handleSubmit};
}

export default UseEditProduct;
