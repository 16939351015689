import { useEffect, useState } from 'react';

const UseReport = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isEmpty, setIsEmpty] = useState(false);
    const [info, setInfo] = useState({
        total: '',
        incompletes: '',
        model: '',
        colour: '',
        colour2: '',
        size: '',
        alone: '',
    });
    
    

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch(`https://api.gestor.mkr.cl/report/completes/${localStorage.getItem('username')}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setInfo({
                            total: data['total'],
                            incompletes: data['incompletes'],
                            model: data['model'],
                            colour: data['colour'],
                            colour2: data['colour2'],
                            size: data['size'],
                            alone: data['alone'],
                            
                        });
                        if(data['total'] === 0 && data['incompletes'] === 0 && data['model'] === 0 && data['colour'] === 0 && data['colour2'] === 0 && data['size'] === 0 && data['alone'] === 0){
                            setIsEmpty(true);
                        } 
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
    }, [isLoading]);

   
    return {info, isEmpty};
}

export default UseReport;
