import React from 'react';

const Pagina = ({totalPages, paginate, currentPage}) => {
    const pageNumbers = [...Array(totalPages).keys()].map(num => num +1);
    return (
        <div className="flex justify-center my-5">
            {currentPage === 1 ? (
                <div className="border-2 border-gray-400 divide-x-2">
                    <button className="px-2  bg-gray-400" onClick={() => paginate(1)}>
                        {pageNumbers[0]}
                    </button>
                    <button className="px-2 " onClick={() => paginate(2)}>
                        {pageNumbers[1]}
                    </button>
                    <button className="px-2 " onClick={() => paginate(3)}>
                        {pageNumbers[2]}
                    </button>
                    <label className="px-2">...</label>
                    <button className="px-2 " onClick={() => paginate(pageNumbers[pageNumbers.length-2])}>
                        {pageNumbers[pageNumbers.length-2]}
                    </button>
                    <button className="px-2  " onClick={() => paginate(pageNumbers[pageNumbers.length-1])}>
                        {pageNumbers[pageNumbers.length-1]}
                    </button>
                </div>
                
            
            ):(<div>
                {currentPage === pageNumbers.length ? (
                    <div className="border-2 border-gray-400 divide-x-2">
                        <button className="px-2" onClick={() => paginate(1)}>
                            {pageNumbers[0]}
                        </button>
                        <button className="px-2" onClick={() => paginate(2)}>
                            {pageNumbers[1]}
                        </button>
                        <button className="px-2" onClick={() => paginate(3)}>
                            {pageNumbers[2]}
                        </button>
                        <label>...</label>
                        <button className="px-2" onClick={() => paginate(pageNumbers[pageNumbers.length-2])}>
                            {pageNumbers[pageNumbers.length-2]}
                        </button>
                        <button className="px-2 bg-gray-400" onClick={() => paginate(pageNumbers[pageNumbers.length-1])}>
                            {pageNumbers[pageNumbers.length-1]}
                        </button>
                    </div>
                ): (
                    <div className="border-2 border-gray-400 divide-x-2">
                        <button className="px-2" onClick={() => paginate(1)}>
                            Primera
                        </button>
                        <label className="px-2">...</label>
                        <button className="px-2" onClick={() => paginate(currentPage-1)}>
                            {currentPage-1}
                        </button>
                        <button className="px-2 bg-gray-400" onClick={() => paginate(currentPage)}>
                            {currentPage}
                        </button>
                        <button className="px-2" onClick={() => paginate(currentPage+1)}>
                            {currentPage+1}
                        </button>
                        <label>...</label>
                        <button className="px-2" onClick={() => paginate(pageNumbers[pageNumbers.length-1])}>
                            Última
                        </button>
                    </div>
                )}
                
            </div>)}
            
        </div>
    );
}

export default Pagina;
