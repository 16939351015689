import { useEffect, useState } from 'react';



const UseEditCategory = (name) => {
    const [isLoading, setIsLoading] = useState(true);
    const [selectorLoading, setSelectorLoading] = useState(true);
    const [dataSelector, setDataSelector] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [errors, setErrors] = useState([])
    const [values, setValues] = useState({
        category_name: '',
        father: '',
        dynamicFields: []
    });
    useEffect(() => {
        var config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch(`https://api.gestor.mkr.cl/get_category/${name.name}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        
                        setValues({
                            ...values,
                            category_name: data.category_name,
                            level: data.level,
                            father: data.father,
                            dynamicFields: data.attributes
                        })
                        console.log(values)
                    })
                })
            .catch(err => {
                setIsLoading(true);
            })
        }
        config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(selectorLoading){
            setSelectorLoading(false);
            fetch("https://api.gestor.mkr.cl/to_show_select/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setDataSelector(data)
                    })
                })
            .catch(err => {
                setIsLoading(true);
            })
        }
    }, [isLoading, values, name, selectorLoading]);
    
    const handleSelector = (e) => {
        var level_selected = 0;
        if(e.value === '') {
            level_selected = 0;
        } else {
            level_selected = e.level + 1;
        }
        setValues({
            ...values,
            level: level_selected,
            father:e.value
        })
    }
    const handleAddFields = () => {
        setValues({
            ...values,
            dynamicFields : [...values.dynamicFields, {name_attribute:'', old:'', type:''}]
        });
        setErrors([...errors, ''])
    }
    const handleDynamicInput = (e, index) => {
        const value = e.target.value;
        var errs = errors;
        var group = [...values.dynamicFields];
        group[index].name_attribute = value;
        if (group[index].name_attribute.length > 50) {
            errs[index] = 'El largo del nombre ha excedido el límite';
            setErrors(errs);
        } else {
            errs[index] = '';
            setErrors(errs);
        } 
        setValues({
            ...values,
            dynamicFields: group
        });
    }
    const handleCatSelector = (e, index) => {
        const group = [...values.dynamicFields];
        group[index].type = e.value;
        setValues({
            ...values,
            dynamicFields: group
        });
    }
    
    const handleSubmit = e => {
        e.preventDefault();    
        console.log(values)
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({values, user:localStorage.getItem('username')})
        }
        fetch('https://api.gestor.mkr.cl/update_category/', config)
            .then(response => {
                response.json()
                .then(data => {
                    if(data.ok===200){
                        setIsOpen(true);
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })

    }

    const keepOnPage = () => {
        setIsOpen(false);
        setIsLoading(true);
    }
    const checkErrors = () => {
        var has_errors = false;
        errors.forEach(err => {
            if(err !== ''){
                has_errors = true;
            }
        });
        return has_errors;

    }
    return {values, errors, dataSelector, isOpen, checkErrors, keepOnPage, handleSelector, handleAddFields, handleDynamicInput, handleCatSelector, handleSubmit};
}

export default UseEditCategory;
