import { useEffect, useState } from 'react'

const UseShowCategory = (name) => {
    const [data, setData] = useState({
        dynamicFields: []
    });
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        var config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch(`https://api.gestor.mkr.cl/get_category/${name.name}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        console.log("get category")
                        console.log(data)
                        setData({
                            ...data,
                            category_name: data.category_name,
                            father: data.father,
                            dynamicFields: data.attributes
                        })
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
    }, [isLoading, name]);
    return {data}
}

export default UseShowCategory;
