import React from 'react';
import Select from 'react-select';
import UseSizes from './UseSizes';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import Logout from '../Others/Logout';

const Sizes = () => {
    const {values, lengths, filters, categories, leakedData, isOpen, error, message, handleSelector, deleteSize, handleFilterInput, handleFilterSubmit, filtersOff, setIsOpen, handleInput, handleSubmit} = UseSizes();
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / </label>
                            <label className="font-bold">Tallas</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Tallas</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mt-5">
                <div className="lg:w-1/3 ml-10 lg:h-screen">
                    <div className="text-lg font-bold mt-10">
                        <div className="bg-red-500"></div>
                        Agregar Talla
                    </div>
                    <div className="mr-10 mt-10">
                        <label className="font-bold">Nombre</label>
                        <input
                            name="size_name"
                            value={values.size_name}
                            onChange={handleInput}
                            placeholder="Ingrese la nueva talla"
                            className="px-2 w-full h-8 sm:text-sm border-2 border-gray-300 rounded-md" 
                        />
                        {error !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error}</label>
                        ): (null)}
                    </div>
                    <div className="mr-10 mt-10 flex flex-col">
                        <label className="font-bold">Categoría Asociada</label>
                        <Select
                            placeholder="Seleccione la Categoría padre"
                            options={categories}
                            onChange={handleSelector}
                            className="" 
                        />
                    </div>
                    <div className="flex flex-row-reverse mr-10">
                        <button onClick={handleSubmit} className="mb-2 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-600 rounded shadow  hover:shadow-lg hover:bg-blue-700 focus:outline-none">Agregar</button>                    
                    </div>
                </div>
                <div className="lg:w-2/3 mt-2">
                    <div className="mx-2"> 
                        <table className="w-full">
                            <thead className="">
                                <tr className="h-10">
                                    <th className="w-2/5 text-left">
                                        <input
                                            name="size_name"
                                            value={filters.size_name}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por nombre"
                                        />

                                    </th>
                                    <th className="w-2/5 text-left ">
                                        <input
                                            name="detail"
                                            value={filters.detail}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por composición"
                                        />
                                    </th>
                                    <th className="w-1/5 text-left ">
                                        {lengths.dataLength !== lengths.leakedLength ? (
                                            <button onClick={filtersOff} className="px-2 py-2 text-xs font-medium text-center text-white uppercase transition bg-red-700 rounded shadow ripple hover:shadow-lg hover:bg-red-800 focus:outline-none">
                                                x Filtros
                                            </button>
                                        ): (null)}
                                        
                                    </th>
                                    
                                </tr>
                                <tr>
                                    <th className="w-2/5 text-left ">Nombre Talla</th>
                                    <th className="w-2/5 text-left">Detalle</th>
                                    <th className="w-1/5 text-left">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leakedData.map((size, index) => (
                                    <TableRow index={index} size={size} deleteSize={deleteSize}/>
                                ))}                              
                            </tbody>
                        </table>
                    </div>  
                </div>
            </div>
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  onRequestClose={() => setIsOpen(false)} className="flex justify-center my-40">
                    <div className="sm:w1/2 md:w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <h2 className="font-bold mx-10 -mt-2">{message}</h2>
                        <h2 className="mx-10 ">Puedes editar y/o agregar otra Talla cerrando este mensaje</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <button type="button " onClick={() => setIsOpen(false)} className="mx-1 px-10  border border-gray-300 font-medium text-sm rounded py-2 px-8 shadow-lg uppercase">
                                Volver
                            </button>
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
        
    )
}

const TableRow = ({index, size, deleteSize}) => {
    if(index%2 === 0) {
        return (
            <tr key={index} className="py-3 bg-white">
                <td>{size.size_name}</td>
                <td>{size.detail}</td>
                <td className="py-3">
                    <div className="flex flex-row">
                        <Link to={`sizes/edit/${size.id}`}>
                            <div className="flex flex-col pr-8">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </Link>
                        <button onClick={()=>deleteSize(size.id)}>
                            <div className="flex flex-col">
                                <img src={process.env.PUBLIC_URL + "/icons/delete.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Borrar</label>
                            </div>
                        </button>
                    </div>
                </td>
            </tr>
        )
    } else {
        return (
            <tr key={index} className="py-3 bg-gray-200 py-3">
                <td  className="rounded-l-lg">{size.size_name}</td>
                <td>{size.detail}</td>
                <td className="rounded-r-lg py-3">
                    <div className="flex flex-row">
                        <Link to={`sizes/edit/${size.id}`}>
                            <div className="flex flex-col pr-8">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </Link>
                        <button onClick={()=>deleteSize(size.id)}>
                            <div className="flex flex-col">
                                <img src={process.env.PUBLIC_URL + "/icons/delete.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Borrar</label>
                            </div>
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
    
    
}
export default Sizes;
;