import React from 'react'
import Select from 'react-select';
import { useParams } from 'react-router';
import UseEditSize from './UseEditSize';
import Modal from 'react-modal';
import { Link, Prompt } from 'react-router-dom';
import Logout from '../Others/Logout';


const EditSize = () => {
    const size = useParams();
    const {values, isOpen, categories, handleSelector, handleChange, handleSubmit} = UseEditSize(size);
    return (
        <div>
            {isOpen === false ? (
                <Prompt
                    when={true}
                    message="Seguro de salir?"
                />
            ): (null)}
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / Tallas / </label>
                            <label className="font-bold">Edición de Talla</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Edición Talla {values.size_name}</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="mx-10 mt-10 ">
                <div className="flex">
                    <label className="w-1/5 h-8 font-semibold">Nombre</label>
                    <input
                        name="size_name"
                        value={values.size_name}
                        onChange={handleChange}
                        className="w-4/5 h-8  bg-gray-200 rounded-md" 
                    />
                </div>

            </div>
            <div className="flex flex-col md:flex-row mt-5 mx-10">
                <label className="w-full md:w-1/5 h-8 font-semibold">Categoría Asociada</label>
                <Select
                    options={categories}
                    onChange={handleSelector}
                    placeholder={values.category}
                    className="w-full md:w-4/5 h-8" 
                />
            </div>
            <div className="mx-10 mt-6">
                <div className="flex">
                    <label className="w-1/5 h-8 font-semibold">Detalle</label>
                    <label>{values.detail}</label>
                </div>

            </div>
            <button onClick={handleSubmit} className="mx-10 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                Guardar
            </button>
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  className="flex justify-center my-40">
                    <div className="min-w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <div className="flex mx-10 -mt-2">
                            <img className="h-10" src={process.env.PUBLIC_URL + "/Correct.png"} alt=""/>
                            <h2 className="font-bold mt-3">Tallas editado Correctamente</h2>
                        </div>
                        <h2 className="mx-10 ">Puedes seguir editando o o volver a la lista de Tallas</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <Link to="/sizes"> 
                                <button className="mr-5 px-4  text-sm  text-white bg-blue-700 font-medium rounded py-2  shadow-lg uppercase">
                                Lista de Tallas
                                </button>
                            </Link>
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
    )
}

export default EditSize;
