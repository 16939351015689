import UseCodes from './UseCodes';
import Modal from 'react-modal';
import Logout from '../Others/Logout';


const Codes = () => {
    const {values, results, isOpen, error, message, stateResult, setIsOpen, handleInput, handleSubmit, submitChilds} = UseCodes();
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / </label>
                            <label className="font-bold">Asignación de Códigos</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Asignación de Códigos</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mt-5">
                <div className="lg:w-1/3 ml-10 lg:h-screen">
                    <div className="text-lg font-bold mt-10">
                        Asignar Códigos
                    </div>
                    <div className="mr-10 mt-10">
                        <div className="flex flex-col">
                            <label className="font-bold">Código Ficha Padre</label>
                            <label className="italic text-sm font-medium text-gray-400">Si quiere asociar los códigos mkr a una ficha nueva escribir "NUEVA"</label>
                        </div>
                        <input
                            name="ficha_code"
                            value={values.ficha_code}
                            onChange={handleInput}
                            placeholder="Ingrese el Código de la Ficha Padre"
                            className="px-2 w-full h-8 sm:text-sm border-2 border-gray-300 rounded-md" 
                        />
                        {error.ficha_code !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error.ficha_code}</label>
                        ): (null)}
                    </div>
                    <div className="mr-10 mt-10 flex flex-col">
                        <label className="font-bold">Códigos Mkr</label>
                        <input
                            name="codes"
                            value={values.codes}
                            onChange={handleInput}
                            placeholder="Ingrese los Códigos MKR separados por coma"
                            className="px-2 w-full h-8 sm:text-sm border-2 border-gray-300 rounded-md" 
                        />
                        {error.codes !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error.codes}</label>
                        ): (null)}
                    </div>
                    <button onClick={handleSubmit} className="mb-2 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">Agregar</button>
                </div>
                <div className="lg:w-2/3 mt-2">
                    <div className="mx-2"> 
                        {results.length > 0 ? (
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th className="w-1/4 text-left">Código</th>
                                        <th className="w-1/4 text-left">Mensaje</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {results.map((result, index) => (
                                    <TableRow index={index} code={result.code} msg={result.msg}/>
                                ))} 
                                </tbody>
                            </table>
                        ):(null)}
                        
                    </div>
                    { stateResult === 'Preparado' ? (
                        <button onClick={submitChilds} className="mb-2 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">Aplicar Hijos</button>
                    ): null}
                </div>
            </div>
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  onRequestClose={() => setIsOpen(false)} className="flex justify-center my-40">
                    <div className="sm:w1/2 md:w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <h2 className="font-bold mx-10 -mt-2">{message}</h2>
                        <h2 className="mx-10 ">Puedes editar y/o agregar otros hijos cerrando este mensaje</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <button type="button " onClick={() => setIsOpen(false)} className="mx-1 px-10  border border-gray-300 font-medium text-sm rounded py-2 px-8 shadow-lg uppercase">
                                Volver
                            </button>
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
        
    )
}

const TableRow = ({index, code, msg}) => {
    if(index%2 === 0) {
        return (
            <tr key={index} className="bg-white">
                <td>{code}</td>
                <td>{msg}</td>
            </tr>
        )
    } else {
        return (
            <tr key={index} className="py-3 bg-gray-200 py-3">
                <td className="rounded-l-lg">{code}</td>
                <td>{msg}</td>
            </tr>
        )
    }
    
    
}
export default Codes;
