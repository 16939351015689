import React from 'react';
import UseHome from './UseHome';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal'
const Home = () => {
    const {handleChange, handleGet, handleSubmit, code, isFound, isOpen, setIsOpen} = UseHome();
    
    return (
        <div className="h-screen  bg-gray-700">
            <div className="">            
                <div className="grid justify-items-center mx-auto px-6 my-12 ">
                    <h2 className="text-4xl font-bold mb-2 text-white">
                    Agregar Producto
                    </h2>
                    <h3 className="text-2xl mb-4 text-gray-200">
                    ¿Desea Agregar algún producto?
                    </h3>
                    
                    <form>
                        <input
                            name="code"
                            value={code}
                            onChange={handleChange}
                            placeholder="Ingrese el código aquí" 
                            className="bg-white text-center font-bold rounded-full py-4 px-8 shadow-lg uppercase"
                        />
                        <button onClick={handleGet}className=" mx-2 bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
                        Agregar
                        </button>
                    </form>
                    
                </div>
                {isFound==='NotFound' ? (
                    <Modal isOpen={isOpen} ariaHideApp={false}  onRequestClose={() => setIsOpen(false)} className="flex justify-center my-40">
                        <div className="sm:w1/2 md:w-1/3 py-10 bg-white rounded-lg shadow-lg">
                            <h2 className="font-bold mx-10 -mt-2">Producto no Encontrado</h2>
                            <h2 className="mx-10 ">Está seguro que desea Agregar el producto con el código "{code}" </h2>
                            <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                                <Link to="/addProduct"> 
                                    <button onClick={handleSubmit} className="mr-5 px-10  text-sm  text-white bg-blue-700 font-medium rounded py-2 px-8 shadow-lg uppercase">
                                    Agregar
                                    </button>
                                </Link>
                                <button type="button " onClick={() => setIsOpen(false)} className="mx-1 px-10  border border-gray-300 font-medium text-sm rounded py-2 px-8 shadow-lg uppercase">
                                    Volver
                                </button>
                            </div>
                        </div>
                        
                    </Modal>
                ): null}
                {isFound==='Found' ? 
                (
                    <Redirect to={`product/show/${code}`}></Redirect>
                        
                ):null}
                {isFound==='Created' ? (
                    <Redirect to={`product/edit/${code}`}></Redirect>
                ):null}
            </div>
        </div>
        )
}

export default Home;
