import Select from 'react-select';
import { Link } from 'react-router-dom';
import UseCategory from './UseCategory';
import Modal from 'react-modal';
import Logout from '../Others/Logout';


const Category = () => {
    const {leakedData, lengths, filters, dataSelector, values, isOpen, error, message, deleteCat, filtersOff, handleFilterInput, handleFilterSubmit, setIsOpen, handleInput, handleSelector, handleSubmit} = UseCategory();
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / </label>
                            <label className="font-bold">Categorías</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Categorías</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mt-5">
                <div className="lg:w-1/3 ml-10 lg:h-screen">
                    <div className="text-lg font-bold mt-10">
                        Agregar Categoría
                    </div>
                    <div className="mr-10 mt-10">
                        <label className="font-bold">Nombre</label>
                        <input
                            name="name_category"
                            value={values.name_category}
                            onChange={handleInput}
                            placeholder="Ingrese el nombre de la categoría"
                            className="px-2 w-full h-8 sm:text-sm border-2 border-gray-300 rounded-md" 
                        />
                        {error !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error}</label>
                        ): (null)}
                    </div>
                    <div className="mr-10 mt-10 flex flex-col">
                        <label className="font-bold">Categoría Padre</label>
                        <label className="italic text-sm font-medium text-gray-400">En Caso de que esta sea la categoría padre, seleccione "Es Padre"</label>
                        <Select
                            placeholder="Seleccione la Categoría padre"
                            options={dataSelector}
                            onChange={handleSelector}
                            className="" 
                        />
                    </div>
                    <button onClick={handleSubmit} className="mb-2 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">Agregar</button>
                </div>
                <div className="lg:w-2/3 mt-2">
                    <div className="mx-2"> 
                        <table className="w-full">
                            <thead className="">
                                <tr className="h-10">
                                    <th className="w-8/12 text-left">
                                        <input
                                            name="name"
                                            autoComplete="off"
                                            value={filters.name}
                                            placeholder="Filtrar por nombre"
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                        />

                                    </th>
                                    <th className="w-1/12 text-left ">
                                        <input
                                            name="level"
                                            autoComplete="off"
                                            value={filters.level}
                                            placeholder="Filtrar por nivel"
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                        />
                                    </th>
                                    <th className="w-3/12 text-left">
                                        {lengths.dataLength !== lengths.leakedLength ? (
                                            <button onClick={filtersOff} className="px-2 py-2 text-xs font-medium text-center text-white uppercase transition bg-red-700 rounded shadow ripple hover:shadow-lg hover:bg-red-800 focus:outline-none">
                                                x Filtros
                                            </button>
                                        ): (null)}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="w-8/12 text-left ">Nombre Categoría</th>
                                    <th className="w-1/12 text-left">Nivel</th>
                                    <th className="w-3/12 text-left">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leakedData.map((category, index) => (
                                    <TableRow key={index} index={index} category={category} deleteCat={deleteCat}/>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  onRequestClose={() => setIsOpen(false)} className="flex justify-center my-40">
                    <div className="sm:w1/2 md:w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <h2 className="font-bold mx-10 -mt-2">{message}</h2>
                        <h2 className="mx-10 ">Puedes editar y/o agregar otra categoría cerrando este mensaje</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <button type="button " onClick={() => setIsOpen(false)} className="mx-1 px-10  border border-gray-300 font-medium text-sm rounded py-2 px-8 shadow-lg uppercase">
                                Volver
                            </button>
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
        
    )
}

const TableRow = ({index, category, deleteCat}) => {
    if(index%2 === 0) {
        return (
            <tr key={index} className="py-3">
                {category.level === 1 ? (
                    <td>{category.category_name}</td>
                ): null}
                {category.level === 2 ? (
                    <td>-{category.category_name}</td>
                ): null}
                {category.level === 3 ? (
                    <td>--{category.category_name}</td>
                ): null}
                {category.level === 4 ? (
                    <td>---{category.category_name}</td>
                ): null}
                {category.level === 5 ? (
                    <td>----{category.category_name}</td>
                ): null}
                <td>{category.level}</td>
                <td className="rounded-r-lg py-3">
                    <div className="flex flex-row">
                        <Link to={`categories/show/${category.category_name}`}>
                            <div className="flex flex-col pr-8 py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/eye.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Ver</label>
                            </div>
                        </Link>
                        <Link to={`categories/edit/${category.category_name}`}>
                            <div className="flex flex-col  pr-8 py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </Link>
                        <button onClick={()=>deleteCat(category.category_name)}>
                            <div className="flex flex-col  py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/delete.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Borrar</label>
                            </div>
                        </button>
                    </div>
                </td>
            </tr>
        )
    } else {
        return (
            <tr key={index} className="py-3 bg-gray-200">
                {category.level === 1 ? (
                    <td className="rounded-l-lg">{category.category_name}</td>
                ): null}
                {category.level === 2 ? (
                    <td className="rounded-l-lg">-{category.category_name}</td>
                ): null}
                {category.level === 3 ? (
                    <td className="rounded-l-lg">--{category.category_name}</td>
                ): null}
                {category.level === 4 ? (
                    <td className="rounded-l-lg">---{category.category_name}</td>
                ): null}
                {category.level === 5 ? (
                    <td className="rounded-l-lg">----{category.category_name}</td>
                ): null}
                <td>{category.level}</td>
                <td className="rounded-r-lg py-3">
                    <div className="flex flex-row">
                        <Link to={`categories/show/${category.category_name}`}>
                            <div className="flex flex-col pr-8 py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/eye.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Ver</label>
                            </div>
                        </Link>
                        <Link to={`categories/edit/${category.category_name}`}>
                            <div className="flex flex-col  py-1 pr-8">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </Link>
                        <button onClick={()=>deleteCat(category.category_name)}>
                            <div className="flex flex-col  py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/delete.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Borrar</label>
                            </div>
                        </button>
                    </div>
                </td>
            </tr>
        )
    }
    
    
}

export default Category;
