import {useEffect, useState} from 'react';

const useLogin = (callback) => {
    const [error, setError] = useState(false);
    const [values, setValues] = useState({
      username: '',
      password: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleChange = e => {
        const {name, value} = e.target;
        setError(false);
        setValues({
            ...values,
            [name]: value
        });
    }
    const handleSubmit = e => {
        e.preventDefault();
        const config = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify({
                username: values.username,
                password: values.password
            })
        }
        fetch('https://api.gestor.mkr.cl/login/', config)
            .then(response => {
                response.json()
                .then(data => {
                    if(data.token !== '') {
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('username', values.username)
                        setIsSubmitting(true);
                    } else {
                        setError(true);
                    }
                    
                })
            })
            .catch(err => {
                setError(true);
                console.log(err)
            })
    }
    useEffect(() => {
        
        if(isSubmitting) {
            callback();

        }
    });
    return {handleChange, handleSubmit, values, error};
}

export default useLogin;