import { useEffect, useState } from 'react';

const UseCategory = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [selectorLoading, setSelectorLoading] = useState(true)
    const [dataSelector, setDataSelector] = useState([])
    const [data, setData] = useState([]);
    const [leakedData, setLeakedData] = useState([]);
    const [filters, setFilters] = useState({
        name: '',
        level: '',
    });
    const [values, setValues] = useState({
        name_category: '',
        level: 1,
        father_name: 'Es Padre'
    });
    const [lengths, setLengths] = useState({
        dataLength: 0,
        leakedLength: 0
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch("https://api.gestor.mkr.cl/get_categories/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setData(data);
                        setLeakedData(data);
                        setLengths({
                            dataLength: data.length,
                            leakedLength: data.length
                        });
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
        if(selectorLoading){
            setSelectorLoading(false);
            fetch("https://api.gestor.mkr.cl/to_show_select/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        console.log("datra",data)
                        setDataSelector(data)
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
    }, [isLoading, selectorLoading]);

    const handleInput = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
        if(name==="name_category") {
            setError('');
        }
    }
    const handleSelector = (e) => {
        var level_selected = 1;
        if(e.value !== '') {
            level_selected = e.level + 1;
        }
        setValues({
            ...values,
            level: level_selected,
            father_name:e.value
        })
    } 
    const handleSubmit = e => {
        e.preventDefault();
        if(values.name_category === '') {
            setError('No ha ingresado el nombre');
        } else {
            const config = {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({values, user:localStorage.getItem('username')})
            }
            fetch('https://api.gestor.mkr.cl/add_category/', config)
                .then(response => {
                    response.json()
                    .then(data => {
                        console.log(data)
                        if(data.status===200) {
                            setIsLoading(true);
                            setSelectorLoading(true);
                            setIsOpen(true);
                            setMessage('Categoría Agregado Correctamente');
                        }
                        if(data.status===201) {
                            setIsOpen(true);
                            setMessage(`La Categoría ${values.name_category}, ya se encuentra en los registros`);
                        }                        
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const handleFilterInput = (e) => {
        const {name, value} = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    } 
    const cleanString = (str) => {
        var clean = str.toUpperCase();
        clean = clean.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        clean = clean.replace(/[^a-zA-Z0-9\s]+/g, "");
        clean = clean.split(" ");
        return clean;
    }
    const compareStrings = (arr1, arr2) => {
        var bool = false
        arr1.forEach((str) => {
            arr2.forEach((str2) => {
                if(str2.includes(str) === true){
                    bool = true;
                }
            })
        });
        return bool;
    }

    const handleFilterSubmit = (e) => {
        console.log(data);
        const {name, value} = e.target;
        if(e.key==="Enter") {
            var cleanValue = cleanString(value);
            var arr = [];
            if(name==="name") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    data.forEach(element  => {
                        var cleanElement = cleanString(element.category_name);
                        if(compareStrings(cleanValue, cleanElement) === true) {
                            arr.push(element);
                        }
                    });
                    setLeakedData(arr);
                    setLengths({
                        ...lengths,
                        leakedLength: arr.length
                    });
                    setFilters({
                        ...filters,
                        [name]: ''
                    });
                }
            }
            if(name==="level") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    data.forEach(element  => {
                        cleanValue = parseInt(cleanValue);
                        var cleanElement = parseInt(element.level);
                        if(cleanValue === cleanElement) {
                            arr.push(element);
                        }
                    });
                    setLeakedData(arr);
                    setLengths({
                        ...lengths,
                        leakedLength: arr.length
                    });
                    setFilters({
                        ...filters,
                        [name]: ''
                    });
                }
            }
        } 
    }
    const filtersOff = () => {
        setFilters({
            size_name: '', 
            detail: ''
        });
        setLeakedData(data);
        setLengths({
            ...lengths,
            leakedLength: data.length
        });
    }
    
    const deleteCat = (name) => {
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user:localStorage.getItem('username')})
        }
        fetch(`https://api.gestor.mkr.cl/delete_cat/${name}`, config)
            .then(response => {
                response.json()
                .then(data => {
                    console.log(data)
                    if(data.status===200) {
                        setIsLoading(true);
                        setSelectorLoading(true);
                    }                        
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    
    return {leakedData, lengths, filters, dataSelector, values, isOpen, error, message, filtersOff, handleFilterInput, handleFilterSubmit, setIsOpen, handleInput, handleSelector, handleSubmit, deleteCat};
}

export default UseCategory;
