import React from 'react';
import Logout from '../Others/Logout';
import Pagina from './Pagina';
import UseAlone from './UseAlone';

const Alone = () => {
    const {lengths, filters, leakedData, totalPages, paginate, currentPage, handleFilterInput, handleFilterSubmit, filtersOff, } = UseAlone();
    const startIndex = (currentPage-1) * 50;
    const pageData = leakedData.slice(startIndex, startIndex+50);
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / </label>
                            <label className="font-bold">Códigos sin Ficha</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Códigos MKR</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mt-5">
                <div className="lg:w-2/3 mt-2">
                    <div className="mx-2"> 
                        <table className="w-full">
                            <thead className="">
                                <tr className="h-10 ">
                                    <th className="w-1/4 text-left">
                                        <input
                                            name="mkr_sku"
                                            value={filters.mkr_sku}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por código"
                                        />
                                    </th>
                                    <th className="w-1/4 text-left">
                                        <input
                                            name="name"
                                            value={filters.name}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por nombre"
                                        />
                                    </th>
                                    <th className="w-1/4 text-left">
                                        <input
                                            name="brand"
                                            value={filters.brand}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por Marca"
                                        />
                                    </th>
                                    <th className="w-1/4 text-left">
                                        {lengths.dataLength !== lengths.leakedLength ? (
                                            <button onClick={filtersOff} className="px-2 py-2 text-xs font-medium text-center text-white uppercase transition bg-red-700 rounded shadow ripple hover:shadow-lg hover:bg-red-800 focus:outline-none">
                                                x Filtros
                                            </button>
                                        ): (null)}
                                    </th>
                                    
                                </tr>
                                <tr>
                                    <th className="w-1/4 text-left">Código</th>
                                    <th className="w-1/4 text-left">Nombre</th>
                                    <th className="w-1/4 text-left">Marca</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageData.map((code, index) => (
                                    <TableRow index={index} code={code}/>
                                ))}                              
                            </tbody>
                        </table>
                        <Pagina totalPages={totalPages} paginate={paginate} currentPage={currentPage}/>
                    </div>
                    
                </div>
            </div>
        </div>
        
    )
}


const TableRow = ({index, code}) => {
    if(index%2 === 0) {
        return (
            <tr key={index} className="bg-white">
                <td>{code.mkr_sku}</td>
                <td>{code.name}</td>
                <td>{code.brand}</td>
            </tr>
        )
    } else {
        return (
            <tr key={index} className="py-3 bg-gray-200 py-3">
                <td className="rounded-l-lg">{code.mkr_sku}</td>
                <td>{code.name}</td>
                <td>{code.brand}</td>
            </tr>
        )
    }
    
    
}
export default Alone;
