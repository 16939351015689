import React from 'react';
import { Link } from 'react-router-dom';
import Logout from '../Others/Logout';
import UseReport from './UseReport';


const Report = () => {
    const {info, isEmpty} = UseReport();
    return(
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <p>Inicio / </p>
                            <label className="font-bold">Reportes</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Reportes</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mt-5">
                <div className="lg:w-full mt-2 ">
                    {isEmpty === true ? 
                    (
                        <div className='mx-10 mt-3'>
                            <label className="text-lg">No hay marcas asociadas a tu cuenta</label>
                        </div>
                    ):(
                        <div>
                            <div className="flex flex-col  lg:flex-row mx-3 "> 
                                <div className="bg-red-500 shadow-lg shadow-red-500/50 w-1/3 h-48 mx-1 rounded-md border-red-800">
                                    <div className='h-1/6 mx-3 my-3 border-b-4'>
                                        <label className="text-white text-lg">Fichas Incompletas</label>
                                    </div>
                                    <div className='h-5/6 mx-3 my-3 flex flex-row'>
                                        <div className="w-2/3 mt-8">
                                            <p className="text-gray-300 text-5xl">{info.incompletes} / {info.total}</p>
                                        </div>
                                        <div className='w-1/3 mt-20 flex flex-row-reverse'>
                                            <Link to="/reportes/incompletes"> 
                                                <p className="decoration-solid text-white ">Ver Fichas Incompletas</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="bg-green-500 shadow-lg shadow-green-500/50 w-1/3 h-48 mx-1 rounded-md border-red-800">
                                    <div className='h-1/6 mx-3 my-3 border-b-4'>
                                        <label className="text-white text-lg">Fichas sin modelo</label>
                                    </div>
                                    <div className='h-5/6 mx-3 my-3 flex flex-row'>
                                        <div className="w-2/3 mt-8">
                                            <p className="text-gray-300 text-5xl">{info.model} / {info.total}</p>
                                        </div>
                                        <div className='w-1/3 mt-20 flex flex-row-reverse'>
                                            <Link to="/reportes/model"> 
                                                <p className="decoration-solid text-white ">Ver Fichas Sin Modelo</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-blue-500 shadow-lg shadow-blue-500/50 w-1/3 h-48 mx-1 rounded-md border-red-800">
                                    <div className='h-1/6 mx-3 my-3 border-b-4'>
                                        <label className="text-white text-lg">Códigos sin Ficha</label>
                                    </div>
                                    <div className='h-5/6 mx-3 my-3 flex flex-row'>
                                        <div className="w-2/3 mt-8">
                                            <p className="text-gray-300 text-5xl">{info.alone}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col  lg:flex-row mx-3 mt-3"> 
                                <div className="bg-red-600 shadow-lg shadow-red-600/50 w-1/3 h-48 mx-1 rounded-md border-red-800">
                                    <div className='h-1/6 mx-3 my-3 border-b-4'>
                                        <label className="text-white text-lg">Fichas con Hijo Sin Color</label>
                                    </div>
                                    <div className='h-5/6 mx-3 my-3 flex flex-row'>
                                        <div className="w-2/3 mt-8">
                                            <p className="text-gray-300 text-5xl">{info.colour} / {info.total}</p>
                                        </div>
                                        <div className='w-1/3  mt-20 flex flex-row-reverse'>
                                            <Link to="/reportes/colour"> 
                                                <p className="decoration-solid text-white ">Ver Fichas Sin Color</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-green-600 shadow-lg shadow-green-600/50 w-1/3 h-48 mx-1 rounded-md border-red-800">
                                    <div className='h-1/6 mx-3 my-3 border-b-4'>
                                        <label className="text-white text-lg">Fichas con Hijo Sin Color 2</label>
                                    </div>
                                    <div className='h-5/6 mx-3 my-3 flex flex-row'>
                                        <div className="w-2/3 mt-8">
                                            <p className="text-gray-300 text-5xl">{info.colour2} / {info.total}</p>
                                        </div>
                                        <div className='w-1/3 mt-20 flex flex-row-reverse'>
                                            <Link to="/reportes/colour2"> 
                                                <p className="decoration-solid text-white ">Ver Fichas Sin Color 2</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-blue-600 shadow-lg shadow-blue-600/50 w-1/3 h-48 mx-1 rounded-md border-red-800">
                                    <div className='h-1/6 mx-3 my-3 border-b-4'>
                                        <label className="text-white text-lg">Fichas con Hijo Sin Talla</label>
                                    </div>
                                    <div className='h-5/6 mx-3 my-3 flex flex-row'>
                                        <div className="w-2/3 mt-8">
                                            <p className="text-gray-300 text-5xl">{info.size} / {info.total}</p>
                                        </div>
                                        <div className='w-1/3 mt-20 flex flex-row-reverse'>
                                            <Link to="/reportes/size"> 
                                                <p className="decoration-solid text-white ">Ver Fichas Sin Talla</p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    
                    
                </div>
            </div>
        </div>
    );
    
    
}

export default Report;
