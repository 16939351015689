import React from 'react'
import { useParams } from 'react-router';
import UseEditColour from './UseEditColour';
import Modal from 'react-modal';
import { Link, Prompt } from 'react-router-dom';
import Logout from '../Others/Logout';


const EditColour = () => {
    const code = useParams();
    const {values, isOpen, handleChange, handleImage, handleSubmit} = UseEditColour(code);
    return (
        <div>
            {isOpen === false ? (
                <Prompt
                    when={true}
                    message="Seguro de salir?"
            />
            ): (null)}
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / Colores / </label>
                            <label className="font-bold">Edición de Color</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Edición Color {values.code}</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="mx-10 mt-10 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Código</label>
                    <label className="w-full md:w-4/5 h-8  rounded-md px-2">{values.code}</label>
                </div>
            </div>
            <div className="mx-10 mt-3 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                    <input
                        name="colour_name"
                        value={values.colour_name}
                        onChange={handleChange}
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
            </div>
            <div className="mx-10 mt-3 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Thumbnail Actual</label>
                    <img
                        src={values.actual}
                        alt=""
                        className="w-8 h-8 rounded-lg border-2 border-gray-300 object-center" 
                    />
                </div>
            </div>
            <div className="mx-10 mt-3 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Nuevo Thumbnail</label>
                    <input
                        name="img"
                        accept="image/png, image/jpg, image/jpeg"
                        type="file"
                        onChange={handleImage}
                        className="w-full md:w-4/5 h-8 rounded-lg" 
                    />
                </div>
            </div>
            <button onClick={handleSubmit} className="mx-10 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                Guardar
            </button>
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  className="flex justify-center my-40">
                    <div className="min-w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <div className="flex mx-10 -mt-2">
                            <img className="h-10" src={process.env.PUBLIC_URL + "/Correct.png"} alt=""/>
                            <h2 className="font-bold mt-3">Color editado Correctamente</h2>
                        </div>
                        <h2 className="mx-10 ">Puedes seguir editando o o volver a la lista de Colores</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <Link to="/colours"> 
                                <button className="mr-5 px-4  text-sm  text-white bg-blue-700 font-medium rounded py-2 shadow-lg uppercase">
                                Lista de Colores
                                </button>
                            </Link>
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
    )
}

export default EditColour;
