import React from 'react';
import UseLogin from './useLogin'

const Login = ({submitForm}) => {
    const {handleChange, handleSubmit, values, error} = UseLogin(submitForm);
    return (
        <div className="grid md:grid-cols-2">
            <div className="min-h-screen flex items-center justify-center sm:px-6 md:px-12 bg-gray-800">
                <div className="w-8/12 ">
                    <div className="">
                        <img className="h-24 -ml-4" src={process.env.PUBLIC_URL + "/logo_mkr.png"} alt="MKR" />
                        <h2 className="text-3xl font-bold text-white pb-4">
                            Gestor de productos MKR
                        </h2>
                    </div>
                    <div className="container px-12 bg-white rounded-lg">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div className="rounded-md  -space-y-px">
                                <div className="py-4">
                                    <label htmlFor="username" className="font-bold pb-1">Usuario</label>
                                    <input 
                                        id="username" 
                                        name="username" 
                                        type="text" 
                                        autoComplete="username" required 
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                        placeholder="Ingrese su usuario"
                                        value={values.username} 
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="py-4">
                                    <label htmlFor="password" className="font-bold pb-1">Constraseña</label>
                                    <input 
                                    id="password" 
                                        name="password" 
                                        type="password" 
                                        autoComplete="current-password" required 
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" 
                                        placeholder="Ingrese su contraseña"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between pb-4">
                                <div className="flex items-center">
                                    <input id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                    <label htmlFor="remember_me" className="ml-2 block text-sm ">
                                        Recordarme
                                    </label>
                                </div>

                                
                            </div>
                            {error ? (
                                <div className="text-center -mt-15 font-bold text-white">
                                    <h2>Usuario y/o contraseña incorrectas</h2>
                                </div>
                            ): (
                                null
                            )}
                            
                            <div className="pb-4">
                                <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">                            
                                    Iniciar Sesión
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <img src={process.env.PUBLIC_URL + "/login.jpg"} className="object-cover w-full h-full" alt="" ></img>
            </div>
        </div>
    );
}

export default Login;
