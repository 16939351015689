import { useEffect, useState } from 'react';

const UseEditSize = (size) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectorLoading, setSelectorLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [values, setValues] = useState({
        id: '',
        size_name: '',
        detail: '',
        category: ''
    });

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch(`https://api.gestor.mkr.cl/get_size/${size.size}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setValues({
                            ...values,
                            id: data.id,
                            size_name: data.size_name,
                            detail: data.detail,
                            category: data.category
                        })
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
        if(selectorLoading){
            setSelectorLoading(false);
            fetch("https://api.gestor.mkr.cl/get_to_form/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setCategories(data.cat_array);
                    });
                })
            .catch(err => {
                setIsLoading(true);
            })
        }
    }, [isLoading, size, values]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })

    }
    const handleSelector = (e) => {
        const value =  e.value;
        setValues({
            ...values,
            category:value
        })
    } 

    const handleSubmit = e => {
        e.preventDefault();    
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({values, user:localStorage.getItem('username')})
        }
        fetch('https://api.gestor.mkr.cl/update_size/', config)
            .then(response => {
                response.json()
                .then(data => {
                    if(data.ok===200){
                        setIsOpen(true);
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })

    }
    return {values, isOpen, categories, handleSelector, setIsOpen, handleChange, handleSubmit};
}

export default UseEditSize;
