import { useState }from 'react';

const UseHome = (callback) => {
    const [isFound, setIsFound] = useState();
    const [code, setCode] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const handleGet = e => {
        e.preventDefault();
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        var upperCode = code.toUpperCase();
        fetch(`https://api.gestor.mkr.cl/product/${upperCode}`, config)
        .then(response => {
            response.json()
            .then(data => {
                console.log(data)
                if(data.code === "not_found"){
                    setIsFound('NotFound');
                    setIsOpen(true)
                }
                if(data.code==="Found") {
                    setIsFound('Found');
                }
            })
        })
        .catch(err => {
            console.log(err)
        })


    }
    const handleSubmit = (e) => {
        e.preventDefault();
        var upperCode = code.toUpperCase();
        console.log(upperCode)
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            },
            body: JSON.stringify({code: upperCode, user:localStorage.getItem('username')})

        }
        fetch(`https://api.gestor.mkr.cl/addsimpleproduct/`, config)
        .then(response => {
            response.json()
            .then(data => {
                if(data.status === 200) {
                    setIsFound('Created');
                } 
            })
        })
        .catch(err => {
            console.log(err)
        })


    }
    const handleChange = e => {
        const {value} = e.target;
        var upper_code = value.toUpperCase()
        setCode(upper_code);
    }
    
    
    return {handleChange, handleGet, handleSubmit, code, isFound, isOpen, setIsOpen};
}

export default UseHome;
