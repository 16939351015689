import { useEffect, useState } from 'react'

const UseShowProduct = (code) => {
    const [data, setData] = useState({})
    const [isLoading, setIsLoading] = useState(true);
    const [catInputs, setCatInputs] = useState({})
    const [selectorLoading, setSelectorLoading] = useState(true);
    useEffect(() => {
        var config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false)
            fetch(`https://api.gestor.mkr.cl/get_product/${code.code}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setData(data)
                    })
                    
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
        config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(selectorLoading){
            setSelectorLoading(false);
            fetch("https://api.gestor.mkr.cl/get_to_form/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setCatInputs(data.category_fields)
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
        
        
    }, [isLoading, code, selectorLoading]);
    return {data, catInputs};
}

export default UseShowProduct;
