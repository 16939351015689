import { useState } from 'react';

const UseCodes = () => {
    const [message, setMessage] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [values, setValues] = useState({
        ficha_code: '',
        codes: '',
    });
    
    const [valuesChecked, setValuesChecked] = useState({
        ficha_code: '',
        codes: '',
    });
    const [error, setError] = useState({
        ficha_code: '',
        codes: '',
    });
    const [results, setResults] = useState([]);
    const [stateResult, setStateResult] = useState('No Preparado');
    const handleInput = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
        if(values.codes === '') {
            setError({
                ...error,
                codes: 'No ha ingresado ningún código'
            });
        }
        if(values.ficha_code === '') {
            setError({
                ...error,
                ficha_code: 'No ha ingresado el código de la ficha padre'
            });
        }
        if(values.ficha_code !== '' && values.codes !== '') {
            console.log(values);
            const config = {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({values, user:localStorage.getItem('username')})
            }
            fetch('https://api.gestor.mkr.cl/check_childs/', config)
                .then(response => {
                    response.json()
                    .then(data => {
                        console.log(data)
                        if(data['status'] === 200) {
                            setResults(data['results']);
                            check_results(data['results']);
                            setValuesChecked({
                                ficha_code: data['ficha_code'],
                                codes: data['codes'],
                            });

                        }
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
        
    }

    const check_results = (arr_res) => {
        setStateResult('Preparado');
        arr_res.forEach((result => {
            console.log(result)
            if(result.msg !== 'Listo para cargar') {
                console.log("no perparado")
                setStateResult('No Preparado');
            }
        }));
    }

    const submitChilds = () => {
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({valuesChecked, user:localStorage.getItem('username')})
        }
        fetch('https://api.gestor.mkr.cl/add_childs/', config)
            .then(response => {
                response.json()
                .then(data => {
                    console.log(data)
                    if(data.status===200) {
                        setIsOpen(true);
                        setMessage("Ficha Modificada Correctamente");
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    
    return {values, isOpen, error, results, message, stateResult, setIsOpen, handleInput, handleSubmit, submitChilds};
}

export default UseCodes;
