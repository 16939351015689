import React from 'react';
import UseColours from './UseColours';
import Modal from 'react-modal';
import Logout from '../Others/Logout';
import { Link } from 'react-router-dom';


const Colours = () => {
    const {values, lengths, filters, leakedData, isOpen, error, message, deleteColour, handleFilterInput, handleFilterSubmit, filtersOff, setIsOpen, handleInput, handleImage, handleSubmit} = UseColours();
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / </label>
                            <label className="font-bold">Colores</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Colores</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mt-5">
                <div className="lg:w-1/3 ml-10 lg:h-screen">
                    <div className="text-lg font-bold mt-10">
                        Agregar Color
                    </div>
                    <div className="mr-10 mt-10">
                        <label className="font-bold">Código</label>
                        <input
                            name="code"
                            value={values.code}
                            type="text"
                            onChange={handleInput}
                            maxLength={2}
                            placeholder="Ingrese el código del color"
                            className="px-2 w-full h-8 sm:text-sm border-2 border-gray-300 rounded-md" 
                        />
                        {error.code !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error}</label>
                        ): (null)}
                    </div>
                    <div className="mr-10 mt-10">
                        <label className="font-bold">Nombre</label>
                        <input
                            name="colour_name"
                            value={values.colour_name}
                            onChange={handleInput}
                            placeholder="Ingrese el nombre del nuevo color"
                            className="px-2 w-full h-8 sm:text-sm border-2 border-gray-300 rounded-md" 
                        />
                        {error.name !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error}</label>
                        ): (null)}
                    </div>
                    <div className="mr-10 mt-10">
                        <label className="font-bold">Thumbnail</label>
                        <input
                            name="img"
                            accept="image/png, image/jpg, image/jpeg"
                            type="file"
                            onChange={handleImage}
                            className="w-full h-8 sm:text-sm " 
                        />
                        {error.img !== '' ? (
                            <label className="text-sm font-medium text-red-700">{error}</label>
                        ): (null)}
                    </div>
                    <button onClick={handleSubmit} className="mb-2 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">Agregar</button>
                </div>
                <div className="lg:w-2/3 mt-2">
                    <div className="mx-2"> 
                        <table className="w-full">
                            <thead className="">
                                <tr className="h-10 ">
                                    <th className="w-1/4 text-left">
                                        <input
                                            name="code"
                                            value={filters.code}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por código"
                                        />
                                    </th>
                                    <th className="w-1/4 text-left">
                                        <input
                                            name="colour_name"
                                            value={filters.colour_name}
                                            onChange={handleFilterInput}
                                            onKeyDown={handleFilterSubmit}
                                            autoComplete="off"
                                            placeholder="Filtrar por nombre"
                                        />
                                    </th>
                                    <th className="w-1/4 text-left">
                                        {lengths.dataLength !== lengths.leakedLength ? (
                                            <button onClick={filtersOff} className="px-2 py-2 text-xs font-medium text-center text-white uppercase transition bg-red-700 rounded shadow ripple hover:shadow-lg hover:bg-red-800 focus:outline-none">
                                                x Filtros
                                            </button>
                                        ): (null)}
                                    </th>
                                    
                                </tr>
                                <tr>
                                    <th className="w-1/4 text-left">Código</th>
                                    <th className="w-1/4 text-left">Nombre</th>
                                    <th className="w-1/4 text-left">Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leakedData.map((colour, index) => (
                                    <TableRow index={index} colour={colour} deleteColour={deleteColour}/>
                                ))}                              
                            </tbody>
                        </table>
                    </div>
                    
                </div>
            </div>
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  onRequestClose={() => setIsOpen(false)} className="flex justify-center my-40">
                    <div className="sm:w1/2 md:w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <h2 className="font-bold mx-10 -mt-2">{message}</h2>
                        <h2 className="mx-10 ">Puedes editar y/o agregar otro Color cerrando este mensaje</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <button type="button " onClick={() => setIsOpen(false)} className="mx-1 px-10  border border-gray-300 font-medium text-sm rounded py-2 px-8 shadow-lg uppercase">
                                Volver
                            </button>
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
        
    )
}


const TableRow = ({index, colour, deleteColour}) => {
    if(index%2 === 0) {
        return (
            <tr key={index} className="bg-white">
                <td>{colour.code}</td>
                <td>{colour.colour_name}</td>
                <td className="flex py-3">
                    <Link to={`colours/edit/${colour.code}`}>
                        <div className="flex flex-col pr-8">
                            <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                            <label className="text-sm mx-auto font-bold">Editar</label>
                        </div>
                    </Link>
                    <button onClick={() =>deleteColour(colour.code)}>
                        <div className="flex flex-col">
                            <img src={process.env.PUBLIC_URL + "/icons/delete.svg"} alt="" className="h-4 mx-auto"/>
                            <label className="text-sm mx-auto font-bold">Borrar</label>
                        </div>
                    </button>
                </td>
            </tr>
        )
    } else {
        return (
            <tr key={index} className="py-3 bg-gray-200 py-3">
                <td className="rounded-l-lg">{colour.code}</td>
                <td>{colour.colour_name}</td>
                <td className="rounded-r-lg py-3 flex">
                    <Link to={`colours/edit/${colour.code}`}>
                        <div className="flex flex-row pr-8">
                            <div className="flex flex-col">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </div>
                    </Link>
                    <button onClick={() =>deleteColour(colour.code)}>
                        <div className="flex flex-row">
                            <div className="flex flex-col">
                                <img src={process.env.PUBLIC_URL + "/icons/delete.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Borrar</label>
                            </div>
                        </div>
                    </button>
                </td>
            </tr>
        )
    }
    
    
}
export default Colours;
