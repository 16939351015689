import React from 'react';
import { useParams } from 'react-router';
import Select from 'react-select';
import UseEditProduct from './UseEditProduct';
import Modal from 'react-modal';
import { Link, Prompt } from 'react-router-dom';
import Logout from '../Others/Logout';

const EditProduct = () => {
    const code = useParams();
    const {values, errors, brands, sizes, colours, colours2, names,
            catValues, catInputs, isOpen, checkErrors, 
            keepOnPage, handleSimpleInput, handleChildSelector,
            handleChildInput, handleSimpleSelect, handleAddFields, 
            handleDynamicInput, handleCatSelector, 
            handleCatInput, handleSubmit, 
            } = UseEditProduct(code);
    return (
        <div className="mx-10">
            {isOpen === false ? (
                <Prompt
                    when={true}
                    message="Seguro de salir?"
                />
            ): (null)}
            <div className="mt-5"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / Productos / </label>
                            <label className="font-bold">Edición de Ficha</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Edición Ficha {values.code}</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="mt-10 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Código Ficha</label>
                    <label className="w-full md:w-4/5 ">{code.code}</label>
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Código Padre</label>
                    <label className="w-full md:w-4/5 ">{values.code2}</label>
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Fecha Creación</label>
                    <label className="w-full md:w-4/5 ">{values.created}</label>
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Última Modificación</label>
                    <label className="w-full md:w-4/5 ">{values.update}</label>
                </div>
                <div className="flex flex-col md:flex-row  mt-3 ">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Grupo</label>
                    <label className="w-full md:w-4/5 ">{values.group}</label>
                </div>
                <div className="flex flex-col md:flex-row  mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Sub Grupo</label>
                    <label className="w-full md:w-4/5 ">{values.subgroup}</label>
                </div>
                <div className="flex flex-col md:flex-row  mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                    <input
                        name="name"
                        maxLength="33"
                        value={values.name}
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el nombre del Producto"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                {errors.name !== '' ? (
                    <div className="font-semibold flex justify-end">
                        <label className="w-4/5 text-red-600 ">{errors.name}</label>
                    </div>
                ):(null)}
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Modelo</label>
                    <input
                        name="model"
                        value={values.model}
                        maxLength="101"
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el Modelo"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                {errors.model !== '' ? (
                    <div className="font-semibold flex justify-end">
                        <label className="w-4/5 text-red-600 ">{errors.model}</label>
                    </div>
                ):(null)}
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">País</label>
                    <input
                        name="country"
                        value={values.country}
                        maxLength="41"
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el País de Procedencia"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                {errors.country !== '' ? (
                    <div className="font-semibold flex justify-end">
                        <label className="w-4/5 text-red-600 ">{errors.country}</label>
                    </div>
                ):(null)}
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Alto (cm)</label>
                    <input
                        name="height"
                        value={values.height}
                        type="number"
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el Alto del Producto"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Ancho (cm)</label>
                    <input
                        name="width"
                        value={values.width}
                        type="number"
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el Ancho del Producto"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Largo (cm)</label>
                    <input
                        name="lenght"
                        value={values.lenght}
                        type="number"
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el Largo del Producto"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Peso (gr)</label>
                    <input
                        name="weight"
                        value={values.weight}
                        type="number"
                        onChange={handleSimpleInput}
                        placeholder="Ingrese el Peso del producto"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                    />
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Marca</label>
                    <Select
                        options={brands}
                        values={values.brand}
                        onChange={handleSimpleSelect}
                        placeholder={values.brand}
                        className="w-full md:w-4/5 h-8" 
                    />
                </div>
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-24 font-semibold">Descripción Emocional</label>
                    <textarea 
                        name="emotional_description"
                        maxLength="3000"
                        value={values.emotional_description}
                        onChange={handleSimpleInput}
                        placeholder="Ingrese la descripción emocional"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2"
                    ></textarea>
                    
                </div>
                {errors.emotional_description !== '' ? (
                    <div className="font-semibold flex justify-end">
                        <label className="w-4/5 text-red-600 ">{errors.emotional_description}</label>
                    </div>
                ):(null)}
                <div className="flex flex-col md:flex-row mt-3">
                    <label className="w-full md:w-1/5 h-24 font-semibold">Descripción Detallada (No Editable y traida de manager pasa su ayuda)</label>
                    <textarea 
                        name="detail_description"
                        disabled={true}
                        value={values.detail_description}
                        onChange={handleSimpleInput}
                        placeholder="Ingrese la descripción detallada"
                        className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2"
                    ></textarea>
                    
                </div>
                {errors.detail_description !== '' ? (
                    <div className="font-semibold flex justify-end">
                        <label className="w-4/5 text-red-600 ">{errors.detail_description}</label>
                    </div>
                ):(null)}
                
            </div>
            
            
            {/*For Categories */}
            <div className="mt-10"> 
                <label className="font-bold text-lg">Categorías</label>
                <div className="mb-2">
                    {values.categories.map((category, index) => (
                        <div className="border-b-2" key={index}>
                            <div className="mt-3 flex flex-col md:flex-row">
                                <label className="w-full md:w-1/5 h-8 font-semibold">Categoría</label>
                                <Select
                                    options={catValues}
                                    placeholder={category.cat_name}
                                    onChange={(e) => handleCatSelector(e, index)}
                                    className="w-full md:w-4/5 h-8" 
                                />
                            </div>
                            <div>
                                
                                {catInputs[category.cat_name] ? (
                                    <div>
                                        {catInputs[category.cat_name].map((input,forindex) => (
                                        <div className="flex flex-col md:flex-row mt-3" key={forindex}>
                                            <label className="w-full md:w-1/5 h-8 font-semibold">{input["name"]}</label>
                                            <input
                                                value={values.categories[index].values[forindex]}
                                                type={input["type"]}
                                                onChange={(e) => handleCatInput(e, index, forindex)}
                                                className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                                            />
                                        </div>
                                    ))}
                                    </div>
                                ):(null)}
                                </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-row-reverse">
                    <button onClick={(e) =>handleAddFields(e,"categories")} className="flex bg-black hover:bg-grey  font-bold py-2 px-4 rounded ">
                        <span className="text-red-700 mx-2">+</span>
                        <span className="flex flex-row-reverse text-white">Agregar</span>
                    </button>
                </div>
                                
            </div>
            {/*For Childs */}
            <div className="mt-10"> 
                <label className="font-bold text-lg">Productos Hijos</label>
                {values.childs.map((field, index) => (
                    <div className="mt-10" key={index}>
                        <div className="flex flex-col md:flex-row">
                            <label className="w-full md:w-1/5 h-8 font-semibold">Código Completo Hijo</label>
                            <label className="w-full md:w-4/5 ">{field['full_code']}</label>
                        </div>
                        <div className="flex flex-col md:flex-row  mt-3">
                            <label className="w-full md:w-1/5 h-8 font-semibold">SKU</label>
                            <input
                                name="sku"
                                placeholder="Código MKR del producto "
                                value={field['sku']}
                                onChange={(e) => handleChildInput(e, index)}
                                className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                            />
                        </div>
                        {names[field['sku']] ? (
                            <div className="flex flex-col md:flex-row  mt-3">
                                <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                                <label className="w-full md:w-4/5 h-8 rounded-md px-2" >
                                    {names[field['sku']]}
                                </label>
                            </div>
                        ):(
                            <div className="flex flex-col md:flex-row  mt-3">
                                <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                                <label className="w-full md:w-4/5 h-8 rounded-md px-2" ></label>
                            </div>
                        )}
                        <div className="flex flex-col md:flex-row mt-3">
                            <label className="w-full md:w-1/5 h-8 font-semibold">Talla</label>
                            <Select
                                options={sizes}
                                placeholder={field['size']}
                                value={field['size']}
                                onChange={(e) => handleChildSelector(e, index)}
                                className="w-full md:w-4/5 h-8" 
                            />
                        </div>
                        <div className="flex flex-col md:flex-row mt-3">
                            <label className="w-full md:w-1/5 h-8 font-semibold">Color Principal</label>
                            <Select
                                placeholder={field['colour']}
                                value={field['colour']}
                                options={colours}
                                onChange={(e) => handleChildSelector(e, index)}
                                className="w-full md:w-4/5 h-8" 
                            />
                        </div>
                        <div className="flex flex-col md:flex-row mt-3">
                            <label className="w-full md:w-1/5 h-8 font-semibold">Color Terminación</label>
                            <Select
                                options={colours2}
                                placeholder={field['colour2']}
                                value={field['colour2']}
                                onChange={(e) => handleChildSelector(e, index)}
                                className="w-full md:w-4/5 h-8" 
                            />
                        </div>
                        <div className="flex flex-col md:flex-row  mt-3">
                            <label className="w-full md:w-1/5 h-8 font-semibold">Nombre Color Emocional</label>
                            <input
                                name="emotional_colour_name"
                                value={field['emotional_colour_name']}
                                onChange={(e) => handleChildInput(e, index)}
                                placeholder="Ingrese el Nombre del color emocionales"
                                className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                            />
                        </div>
                        
                    </div>
                    
                ))}
                <div className="flex flex-row-reverse border-b-2 border-black pb-1">
                    <button onClick={(e) =>handleAddFields(e,"childs")} className="flex bg-black hover:bg-grey  font-bold py-2 px-4 rounded">
                        <span className="text-red-700 mx-2">+</span>
                        <span className="flex flex-row-reverse text-white">Agregar</span>
                    </button>
                </div>              
            </div>
            {/*For dynamic attributes */}
            <div className="mt-10"> 
                <label className="font-bold text-lg">Atributos adicionales</label>
                <div className="flex flex-col md:flex-row mt-2">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                    <label className="w-full md:w-4/5 h-8  font-semibold">Descripción</label>
                </div>
                {values.dynamicFields.map((field, index) => (
                    <div className="mt-3 flex flex-col md:flex-row h-16 border-b-2" key={index}>
                        <input
                            name="name"
                            value={field.name}
                            onChange={(e) => handleDynamicInput(e, index)}
                            placeholder="Nombre"
                            className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                        />
                        <input
                            name="value"
                            value={field.value}
                            onChange={(e) => handleDynamicInput(e, index)}
                            placeholder="Ingrese el valor"
                            className="w-full md:w-4/5 h-8 rounded-md border-2 border-gray-300 px-2" 
                        />
                        
                    </div>
                ))}
                <div className="flex flex-row-reverse border-b-2 border-black pb-1">
                    <button onClick={(e) =>handleAddFields(e,"dynamicFields")} className="flex bg-black hover:bg-grey  font-bold py-2 px-4 rounded">
                        <span className="text-red-700 mx-2">+</span>
                        <span className="flex flex-row-reverse text-white">Agregar</span>
                    </button>
                </div>              
            </div>
            {checkErrors() === false ? (
                <div className="flex flex-row-reverse">
                    <button onClick={handleSubmit} className="my-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                        Guardar
                    </button>
                </div>
            ):(null)}
            
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  className="flex justify-center my-40">
                    <div className="min-w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <div className="flex mx-10 -mt-2">
                            <img className="h-10" src={process.env.PUBLIC_URL + "/Correct.png"} alt=""/>
                            <h2 className="font-bold mt-3">Producto editado Correctamente</h2>
                        </div>
                        <h2 className="mx-10 ">Puedes seguir editando o o volver a la lista de productos</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <Link to="/productList"> 
                                <button className="mr-5 px-10  text-sm  text-white bg-blue-700 font-medium rounded py-2 px-8 shadow-lg uppercase">
                                Lista de productos
                                </button>
                            </Link>
                            <button type="button " onClick={keepOnPage} className="mx-1 px-10  border border-gray-300 font-medium text-sm rounded py-2 px-8 shadow-lg uppercase">
                                Seguir
                            </button>
                            
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
    )
}

export default EditProduct;
