import React from 'react'

const Logout = () => {
    const logout = () => {
        localStorage.setItem('token', '')
        localStorage.setItem('username', '')
    }
    return (
        <a href="/" className="flex font-semibold" onClick={logout}>
            <img src={process.env.PUBLIC_URL + "/icons/log_out.svg"} className="h-6" alt=""/>
            <label to="/sizes" className="pl-1 font-semibold hover:text-gray-500">Cerrar Sesión</label>
        </a>                
    )
}

export default Logout;
