import { useEffect, useState }from 'react';

const UseSinFcicha = () => {
    const [data, setData] = useState([]);
    const [leakedData, setLeakedData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const dataPerPage = 20;
    const [totalPages, setTotalPages] = useState(0);
    
    const [filters, setFilters] = useState({
        name: '',
        code: '',
        brand: '',
        date:'',
        model:''
    });
    const [lengths, setLengths] = useState({
        dataLength: 0,
        leakedLength: 0
    });
    const [isLoading, setIsLoading] = useState(true);

    const do_filters = () => {
        if(filters.name==='' && filters.code==='' && filters.date==='' && filters.brand==='' && filters.model==='') {
            setLeakedData(data);
            setTotalPages(Math.ceil(data.length / dataPerPage));
            localStorage.setItem('set_filters', "no");

        }
        else {
            var arr = [];
            data.forEach(element=>{
                if(compareStrings(element) === true) {
                    arr.push(element);
                }
            });
            setLeakedData(arr);
            setLengths({
                ...lengths,
                leakedLength: arr.length
            });
            setTotalPages(Math.ceil(arr.length / dataPerPage));
        }
        setCurrentPage(1);
    }

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            fetch("https://api.gestor.mkr.cl/products/without_detail", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setData(data);
                        setIsLoading(false);
                        setLengths({
                            dataLength: data.length,
                            leakedLength: data.length
                        });
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
            .finally(() => {
                if(localStorage.getItem('set_filters_sin') === 'yes') {
                    setFilters({
                        name: localStorage.getItem('filter_name_sin'),
                        code: localStorage.getItem('filter_code_sin'),
                        date: localStorage.getItem('filter_date_sin'), 
                        brand: localStorage.getItem('filter_brand_sin'),
                        model: localStorage.getItem('filter_model_sin'),
                    });
                    do_filters();
                } else {
                    setTotalPages(Math.ceil(data.length / dataPerPage));
                    setLeakedData(data);
                } 
            })
        }
        
    }, [isLoading, data, do_filters]);
   
    const paginate = (number) => {
        setCurrentPage(number);
    }

    const handleInput = (e) => {
        const {name, value} = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    } 
    
    const compareStrings = (element) => {
        console.log(element)
        var bool = true;
        var str1 = '';
        var str2 = '';
        if(filters.name !== '' && bool===true){
            str1 = element.name.toUpperCase();
            str2 = filters.name.toUpperCase();
            if(str1.includes(str2) === false) {
                bool = false;
            }
        }
        if(filters.code !== '' && bool===true){
            str1 = element.ficha_code.toUpperCase();
            str2 = filters.code.toUpperCase();
            if(str1.includes(str2) === false) {
                bool = false;
            }
        }
        if(filters.date !== '' && bool===true){
            str1 = element.last_update.toUpperCase();
            str2 = filters.date.toUpperCase();
            if(str1.includes(str2) === false) {
                bool = false;
            }
        }
        if(filters.brand !== '' && bool===true){
            str1 = element.brand.toUpperCase();
            str2 = filters.brand.toUpperCase();
            if(str1.includes(str2) === false) {
                bool = false;
            }
        }
        if(filters.model !== '' && bool===true){
            str1 = element.model.toUpperCase();
            str2 = filters.model.toUpperCase();
            if(str1.includes(str2) === false) {
                bool = false;
            }
        }
        return bool;
    }

    const handleSubmit = (e) => {
        if(e.key==="Enter") {
            localStorage.setItem('set_filters_sin', "yes");
            localStorage.setItem('filter_name_sin', filters.name);
            localStorage.setItem('filter_brand_sin', filters.brand);
            localStorage.setItem('filter_model_sin', filters.model);
            localStorage.setItem('filter_date_sin', filters.date);
            localStorage.setItem('filter_code_sin', filters.code);
            do_filters();
        } 
    } 
    const filtersOff = () => {
        setFilters({
            name: '',
            code: '',
            date: '', 
            brand: '',
            model: '',
        });
        localStorage.setItem('set_filters_sin', "no");
        localStorage.setItem('filter_name_sin', '');
        localStorage.setItem('filter_brand_sin', '');
        localStorage.setItem('filter_model_sin', '');
        localStorage.setItem('filter_date_sin', '');
        localStorage.setItem('filter_code_sin', '');
        setLeakedData(data);
        setLengths({
            ...lengths,
            leakedLength: data.length
        });
        setTotalPages(Math.ceil(data.length / dataPerPage));
    }
    
    return {leakedData, filters, lengths, dataPerPage, currentPage, totalPages, paginate, filtersOff, handleInput, handleSubmit};
}

export default UseSinFcicha;
