import React from 'react';
import { useParams } from 'react-router';
import UseShowCategory from './UseShowCategory';
import { Link } from 'react-router-dom';
import Logout from '../Others/Logout';


const ShowCategory = () => {
    const name = useParams();
    const {data} = UseShowCategory(name);
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / Categoría / </label>
                            <label className="font-bold">Detalle de Categoría</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Categoría {name.name}</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="mx-10 mt-10 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                    <label className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md">{data.category_name}</label>
                </div>
                <div className="flex flex-col md:flex-row mt-5">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Categoría Padre</label>
                    <label className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md">{data.father}</label>
                </div>
            </div>
            <div className="mt-10"> 
                <label className="mx-10 font-bold text-lg">Atributos Web</label>
            </div>
            <div className="mt-2">
                {data.dynamicFields ? (
                    <div>
                        {data.dynamicFields.length > 0 ? (
                            <div>
                                {data.dynamicFields.map((field, index) => (
                                    <div className="mt-1 mx-10 h-24 border-b-2" key={index}>
                                        <div className="flex flex-col md:flex-row">
                                            <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                                            <label className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md">{field.name_attribute}</label>

                                        </div>
                                        <div className="flex flex-col md:flex-row mt-3">
                                            <label className="w-fulll md:w-1/5 h-8 font-semibold">Tipo</label>
                                            {field.type === "text" ? (
                                                <label className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md">Texto</label>
                                            ): (
                                                <label className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md">Númerico</label>
                                            )}

                                        </div>
                                    </div>
                                ))}
                            </div>
                        ): (
                            <div className="mx-10">
                                <label className="w-1/5 h-8 font-semibold">Esta Categoría no tiene atributos dinámicos</label>
                            </div>
                        )}        
                    </div>
                ):(null)} 
                
                
                
            </div>
            <div className="flex flex-row-reverse mx-10">
                <Link to={`/categories/edit/${name.name}`}>
                    <button className="ml-10 mt-10 px-4 py-2 text-xs font-medium text-center text-white uppercase transition bg-blue-700 rounded shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                            Editar esta Categoría
                    </button>
                </Link>
            </div>
            
        </div>
    )
}

export default ShowCategory;
