import React from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import UseShowProduct from './UseShowProduct';
import Logout from '../Others/Logout';

const ShowProduct = () => {
    const code = useParams();
    const {data, catInputs} = UseShowProduct(code);
    return (
        <div className="mx-10">
            <div className="mt-5 "> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / Productos / </label>
                            <label className="font-bold">Detalle de Producto</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Producto {code.code}</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="mt-10 ">
                <div className="flex">
                    <label className="w-1/5 h-8 font-semibold">Código Ficha</label>
                    <label className="w-4/5 ">{code.code}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Código Padre</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.ficha_code}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Fecha Creación</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.created_date}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Última Modificación</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.last_update}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Nombre</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.name}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Modelo</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.model}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">País</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.country}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Alto (cm)</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.height}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Ancho (cm)</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.width}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Largo (cm)</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.length}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Peso (kg)</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.weight}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Marca</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.brand}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Grupo</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.group}</label>
                </div>
                <div className="flex mt-3">
                    <label className="w-1/5 h-8 font-semibold">Sub Grupo</label>
                    <label className="w-4/5 h-8  bg-gray-200 rounded-md">{data.subgroup}</label>
                </div>
            </div>
            
            
            {/*For Categories */}
            <div className="mt-10"> 
                <label className="font-bold text-lg">Categorias</label>
                <div>
                    {data.categories ? (
                        <div className="mb-2">
                            {data.categories.map((category, index) => (
                                <div className="border-b-2">
                                    <div className="mt-3 flex" key={index}>
                                        <label className="w-1/5 h-8 font-semibold">Categoría</label>
                                        <label className="w-4/5 h-8">{category.cat_name}</label>
                                    
                                    </div>
                                    <div>
                                        {catInputs[category.cat_name] ? (
                                            <div>
                                                {catInputs[category.cat_name].map((input,forindex) => (
                                                    <div className="flex mt-3">
                                                        <label className="w-1/5 h-8 font-semibold">{input.name}</label>
                                                        <label className="w-4/5 h-8 mb-1  bg-gray-200 rounded-md">{category.values[forindex]}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        ):(null)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (null)}
                </div>
                
            </div>
            <div className="mt-10"> 
                <label className="font-bold text-lg">Campos Dinámicos</label>
                <div className="flex mt-2">
                    <label className="w-1/5 h-8 font-semibold">Nombre</label>
                    <label className="w-4/5 h-8  font-semibold">Valor</label>
                </div>
                {data.dynamicFields ? (
                    <div>
                        {data.dynamicFields.map((field, index) => (
                            <div className="mt-3 flex h-16 border-b-2" key={index}>
                                <label className="w-1/5 h-8 bg-gray-200">{field.name}</label>
                                <label className="w-4/5 h-8 ml-2 bg-gray-200 rounded-md">{field.value}</label>
                            </div>
                        ))}
                    </div>
                ): (null)}
                                                               
            </div>
            <div className="flex flex-row-reverse">
                <Link to={`/ficha/edit/${code.code}`}>
                    <button className="my-10 px-4 py-2 text-xs font-medium text-center text-white uppercase transition bg-blue-700 rounded shadow ripple hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                        Editar este Producto
                    </button>
                </Link>
            </div>
            
        </div>
    )
}

export default ShowProduct;
