import { useEffect, useState } from 'react';

const UseColours = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('');
    const [leakedData, setLeakedData] = useState([]);
    const [values, setValues] = useState({
        code: '',
        colour_name: '',
        img: ''
    });
    const [filters, setFilters] = useState({
        code:'',
        colour_name: '', 
        father: ''
    });
    const [lengths, setLengths] = useState({
        dataLength: 0,
        leakedLength: 0
    });
    const [error, setError] = useState({
        name:'',
        code:'',
        img: ''
    });

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch("https://api.gestor.mkr.cl/get_colours/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setData(data.colours);
                        setLeakedData(data.colours);
                        setLengths({
                            dataLength: data.colours.length,
                            leakedLength: data.colours.length
                        });
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
    }, [isLoading]);
    const handleInput = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
        if(name==="colour_name") {
            setError({
                ...error,
                name: ''
            });
        }
        if(name==="code") {
            setError({
                ...error,
                code: ''
            });
        }
    }

    const handleImage = (e) => {
        const name = e.target.name; 
        const value = e.target.files[0];
        setValues({
            ...values,
            [name]: value
        });
        if(name==="img") {
            setError({
                ...error,
                img: ''
            });
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        if(values.colour_name === '') {
            setError({
                ...error,
                name: "No se ha ingresado el nombre"
            });
        }
        if(values.code === ''){
            setError({
                ...error,
                name: "No se ha ingresado el nombre"
            });
        }
        if(values.img === ''){
            setError({
                ...error,
                name: "No se ha ingresado el thumbnail"
            });
        }
        if(error.name==='' && error.code==='' && error.img===''){
            var form_data = new FormData();
            form_data.append('code', values['code']);
            form_data.append('colour_name', values['colour_name']);
            form_data.append('img', values['img']);
            form_data.append('user', localStorage.getItem('username'));
            const config = {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                },
                body: form_data 
            }
            fetch('https://api.gestor.mkr.cl/add_colour/', config)
                .then(response => {
                    response.json()
                    .then(data => {
                        console.log(data)
                        if(data.status===200) {
                            setIsOpen(true);
                            setIsLoading(true);
                            setMessage("Color agregado Correctamente");
                        }
                        if(data.status===400) {
                            setIsOpen(true);
                            setMessage(`El Color ${values.colour_name}, ya se encuentra en los registros`);
                        }   
                        
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    
    const handleFilterInput = (e) => {
        const {name, value} = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    } 
    const cleanString = (str) => {
        var clean = str.toUpperCase();
        clean = clean.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        clean = clean.replace(/[^a-zA-Z0-9\s]+/g, "");
        clean = clean.split(" ");
        return clean;
    }
    const compareStrings = (arr1, arr2) => {
        var bool = false
        arr1.forEach((str) => {
            arr2.forEach((str2) => {
                if(str2.includes(str) === true){
                    bool = true;
                }
            })
        });
        return bool;
    }

    const handleFilterSubmit = (e) => {
        console.log(data);
        const {name, value} = e.target;
        if(e.key==="Enter") {
            var arr = [];
            var cleanValue = cleanString(value);
            if(name==="code") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    data.forEach(element  => {
                        var cleanElement = cleanString(element.code);
                        if(compareStrings(cleanValue, cleanElement) === true) {
                            arr.push(element);
                        }
                    });
                    setLeakedData(arr);
                    setLengths({
                        ...lengths,
                        leakedLength: arr.length
                    });
                    setFilters({
                        ...filters,
                        [name]: ''
                    });
                }
            }
            if(name==="colour_name") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    data.forEach(element  => {
                        var cleanElement = cleanString(element.colour_name);
                        if(compareStrings(cleanValue, cleanElement) === true) {
                            arr.push(element);
                        }
                    });
                    setLeakedData(arr);
                    setLengths({
                        ...lengths,
                        leakedLength: arr.length
                    });
                    setFilters({
                        ...filters,
                        [name]: ''
                    });
                }
            }
            if(name==="emotional_name") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    if(value === '') {
                        setLeakedData(data);
                    } else {
                        data.forEach(element  => {
                            var cleanElement = cleanString(element.father);
                            if(compareStrings(cleanValue, cleanElement) === true) {
                                arr.push(element);
                            }
                        });
                        setLeakedData(arr);
                        setLengths({
                            ...lengths,
                            leakedLength: arr.length
                        });
                        setFilters({
                            ...filters,
                            [name]: ''
                        });
                    }
                }
            }
            
        } 
    }
    const filtersOff = () => {
        setFilters({
            colour_name: '', 
            detail: ''
        });
        setLeakedData(data);
        setLengths({
            ...lengths,
            leakedLength: data.length
        });
    }
    const deleteColour = (code) => {
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user:localStorage.getItem('username')})
        }
        fetch(`https://api.gestor.mkr.cl/delete_colour/${code}`, config)
            .then(response => {
                response.json()
                .then(data => {
                    console.log(data)
                    if(data.status===200) {
                        setIsLoading(true);
                    }                        
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    return {values, lengths, filters, leakedData, isOpen, error, message, deleteColour, handleFilterInput, handleFilterSubmit,filtersOff, setIsOpen, handleInput, handleImage, handleSubmit};
}

export default UseColours;
