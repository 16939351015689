import React from 'react';
import UseProducts from './UseProducts';
import { Redirect } from 'react-router-dom';
import Pagination from './Pagination';
import Content from './Content';
import Logout from '../Others/Logout';


const Products = () => {
    const {leakedData, filters, lengths, dataPerPage, currentPage, totalPages, wasCreated, paginate, filtersOff, handleInput, handleSubmit, handleCreateDetail} = UseProducts();
    return (
        <div>
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / </label>
                            <label className="font-bold">Fichas</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Fichas</label>
                        <div className="mt-2">
                            <button
                                onClick={handleCreateDetail} 
                                className="px-2 py-2 text-sm font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                                + Agregar Ficha
                            </button>
                            {wasCreated!=='' ? 
                            (
                                <Redirect to={`ficha/edit/${wasCreated}`}></Redirect>
                                    
                            ):null}
                        </div>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="lg:flex mx-10 mt-10">
                <div className="w-full mt-2">
                    <div className="mx-2"> 
                        <table className="w-full">
                            <thead>
                                <tr className="h-10">
                                    <th className="w-16 text-left ">
                                        <input
                                            name="code"
                                            autoComplete="off"
                                            value={filters.code}
                                            placeholder="Filtrar por código"
                                            onChange={handleInput}
                                            onKeyDown={handleSubmit}
                                        />
                                    </th>
                                    <th className="w-48 text-left text-left">
                                        <input
                                            name="name"
                                            autoComplete="off"
                                            value={filters.name}
                                            placeholder="Filtrar por nombre"
                                            onChange={handleInput}
                                            onKeyDown={handleSubmit}
                                        />

                                    </th>
                                    <th className="w-32 text-left text-left">
                                        <input
                                            name="brand"
                                            autoComplete="off"
                                            value={filters.brand}
                                            placeholder="Filtrar Marca"
                                            onChange={handleInput}
                                            onKeyDown={handleSubmit}
                                        />
                                    </th>
                                    <th className="w-16 text-left text-left">
                                        <input
                                            name="model"
                                            autoComplete="off"
                                            value={filters.model}
                                            placeholder="Filtrar Modelo"
                                            onChange={handleInput}
                                            onKeyDown={handleSubmit}
                                        />
                                    </th>
                                    <th className="w-32 text-left text-left">
                                        <input
                                            name="date"
                                            autoComplete="off"
                                            value={filters.date}
                                            placeholder="Filtrar por Fecha"
                                            onChange={handleInput}
                                            onKeyDown={handleSubmit}
                                        />
                                    </th>
                                    <th className="w-48 text-left text-left">
                                        {localStorage.getItem('set_filters') === 'yes' ? (
                                            <button onClick={filtersOff} className="px-2 py-2 text-xs font-medium text-center text-white uppercase transition bg-red-700 rounded shadow ripple hover:shadow-lg hover:bg-red-800 focus:outline-none">
                                                x Filtros
                                            </button>
                                        ): (null)}
                                    </th>
                                </tr>
                                <tr>
                                    <th className="w-16 text-left ">Código</th>
                                    <th className="w-48 text-left text-left">Nombre del Producto</th>
                                    <th className="w-32 text-left text-left">Marca</th>
                                    <th className="w-16 text-left text-left">Modelo</th>
                                    <th className="w-32 text-left text-left">Última Actualizacion</th>
                                    <th className="w-48 text-left text-left">Acción</th>
                                </tr>
                            </thead>
                            {leakedData.length !== 0 ? (
                                <Content leakedData={leakedData} currentPage={currentPage} dataPerPage={dataPerPage}/>
                            ):(null)}
                        </table>
                        <Pagination totalPages={totalPages} paginate={paginate} currentPage={currentPage}/>
                    </div>
                    
                </div>
            </div>
            
        </div>
    )
}

export default Products;
