import React from 'react';
import Select from 'react-select';
import { useParams } from 'react-router';
import UseEditCategory from './UseEditCategory';
import Modal from 'react-modal';
import { Link, Prompt } from 'react-router-dom';
import Logout from '../Others/Logout';


const EditCategory = () => {
    const name = useParams();
    const {values, errors, dataSelector, isOpen, checkErrors, keepOnPage, handleSelector, handleAddFields, handleDynamicInput, handleCatSelector, handleSubmit} = UseEditCategory(name);
    const options = [
        {label: "Númerico", value: "number"},
        {label: "Texto", value: "text"}
    ]
    return (
        <div>
            {isOpen === false ? (
                <Prompt
                    when={true}
                    message="Seguro de salir?"
                />
            ): (null)}
            <div className="mt-5 mx-10"> 
                <div className="grid grid-cols-2">
                    <div className="flex flex-col">
                        <div>
                            <label>Inicio / Categoría / </label>
                            <label className="font-bold">Edición de Categoría</label>
                        </div>
                        <label className="font-bold text-4xl py-1">Edición Categoría {name.name}</label>
                    </div>
                    <div className="flex justify-end ">
                        <Logout/>
                    </div>
                </div>
            </div>
            <div className="mx-10 mt-10 ">
                <div className="flex flex-col md:flex-row">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                    <label className="w-full md:w-4/5 h-8 rounded-md">{values.category_name}</label>
                </div>
                <div className="flex flex-col md:flex-row mt-5">
                    <label className="w-full md:w-1/5 h-8 font-semibold">Categoría Padre</label>
                    <Select
                        options={dataSelector}
                        onChange={handleSelector}
                        placeholder={values.father}
                        className="w-full md:w-4/5 h-8" 
                    />
                </div>
            </div>
            <div className="mt-10"> 
                <label className="mx-10 font-bold text-lg">Atributos Web</label>
            </div>
            <div className="mt-2"> 
                {values.dynamicFields ? (
                    <div>
                        {values.dynamicFields.map((field, index) => (
                            <div className="mt-1 mx-10 border-b-2" key={index}>
                                <div className="flex flex-col md:flex-row">
                                    <label className="w-full md:w-1/5 h-8 font-semibold">Nombre</label>
                                    <input
                                        value={field.name_attribute}
                                        maxLength="51"
                                        onChange={(e) => handleDynamicInput(e,index)}
                                        placeholder="Ingrese el nombre del atributo"
                                        className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md" 
                                    />
                                </div>
                                {errors[index] !== '' ? (
                                    <div className="font-semibold flex justify-end">
                                        <label className="w-4/5 text-red-600">{errors[index]}</label>
                                    </div>
                                ) : null}
                                <div className="flex flex-col md:flex-row my-3">
                                    <label className="w-full md:w-1/5 h-8 font-semibold">Tipo</label>
                                    {field.type === "number" ? (
                                        <Select
                                            options={options}
                                            onChange={(e) => handleCatSelector(e, index)}
                                            placeholder={field.type}
                                            className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md" 
                                        />
                                    ):(
                                        <Select
                                            options={options}
                                            onChange={(e) => handleCatSelector(e, index)}
                                            placeholder={field.type}
                                            className="w-full md:w-4/5 h-8  bg-gray-200 rounded-md" 
                                        />
                                    )}
                                    
                                </div>
                            </div>
                        ))}
                    </div>
                ): (null)}
                
                <button onClick={handleAddFields} className="bg-grey-light mx-10 hover:bg-grey text-gray-400 font-bold py-2 px-4 rounded inline-flex items-center">
                    <span>+Agregar</span>
                </button>

            </div>
            {checkErrors() === false ? (
                <button onClick={handleSubmit} className="mx-10 mt-10 inline-block px-6 py-2 text-xs font-medium leading-6 text-center text-white uppercase  bg-blue-700 rounded shadow  hover:shadow-lg hover:bg-blue-800 focus:outline-none">
                    Guardar
                </button>
            ): (null)}
            
            {isOpen === true ? (
                <Modal isOpen={isOpen} ariaHideApp={false}  className="flex justify-center my-40">
                    <div className="min-w-1/3 py-10 bg-white rounded-lg shadow-lg">
                        <div className="flex mx-10 -mt-2">
                            <img className="h-10" src={process.env.PUBLIC_URL + "/Correct.png"} alt="" />
                            <h2 className="font-bold mt-3">Categoría editado Correctamente</h2>
                        </div>
                        <h2 className="mx-10 ">Puedes seguir editando o o volver a la lista de Categorías</h2>
                        <div className="bg-grey-100 mt-10 flex flex-row-reverse">
                            <Link to="/categories"> 
                                <button className="mr-5 px-4  text-sm  text-white bg-blue-700 font-medium rounded py-2 shadow-lg uppercase">
                                Lista de Categorías
                                </button>
                            </Link>
                            <button type="button " onClick={keepOnPage} className="mx-1 px-8  border border-gray-300 font-medium text-sm rounded py-2 shadow-lg uppercase">
                                Seguir
                            </button>
                            
                        </div>
                    </div>
                    
                </Modal>
            ): null}
        </div>
    )
}

export default EditCategory;
