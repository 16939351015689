import React from 'react';
import { Link } from 'react-router-dom';

const SideBar = () => {
    
    return (
        <aside className="bg-gray-800 w-64 min-h-screen flex flex-col">
            <div className="flex items-center  bg-red-600">
                <div className="mx-2">
                    <Link to="/productList" >
                        <img className="" src={process.env.PUBLIC_URL + "/logo_mkr.png"} alt=""/>
                    </Link>
                </div>
            </div>
            <div className=" flex-grow">
                <nav>
                    <ul>
                        
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/products.svg"} className="h-6" alt=""/>
                            <Link to="/" className="px-4 text-white font-semibold">Fichas</Link>                    
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/products.svg"} className="h-6" alt=""/>
                            <Link to="/sin_ficha" className="px-4 text-white font-semibold">Fichas Sin Modificar</Link>                    
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/category.svg"} className="h-6" alt=""/>
                            <Link to="/categories" className="px-4 text-white font-semibold">Categorías</Link>
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/color.svg"} className="h-6" alt=""/>
                            <Link to="/colours" className="px-4 text-white font-semibold">Colores</Link>
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/products.svg"} className="h-6" alt=""/>
                            <Link to="/sizes" className="px-4 text-white font-semibold">Tallas</Link>
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/products.svg"} className="h-6" alt=""/>
                            <Link to="/codigos/asignar" className="px-4 text-white font-semibold">Asignación de Códigos</Link>
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/products.svg"} className="h-6" alt=""/>
                            <Link to="/codes" className="px-4 text-white font-semibold">Códigos Sin Ficha</Link>
                        </li>
                        <li className="flex h-12 border-b-2 border-red-100 border-opacity-100 px-4 my-4">
                            <img src={process.env.PUBLIC_URL + "/icons/products.svg"} className="h-6" alt=""/>
                            <Link to="/reportes" className="px-4 text-white font-semibold">Reportes</Link>
                        </li>
                    </ul>
                    
                </nav>
            </div>
        </aside>
    )
}

export default SideBar;
