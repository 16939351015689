import { useEffect, useState } from 'react'

const UseEditColour = (colour) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [values, setValues] = useState({
        code: '',
        colour_name: '',
        emotional_name: '',
        img: '',
        actual: ''
    });

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch(`https://api.gestor.mkr.cl/get_colour/${colour.colour}`, config)
                .then(response => {
                    response.json()
                    .then(data => {
                        console.log(data)
                        setValues({
                            ...values,
                            code: data.code,
                            colour_name: data.colour_name,
                            emotional_name: data.emotional_name,
                            actual: data.name
                        })
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            })
        }
    }, [isLoading, colour, values]);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        })

    }

    const handleImage = (e) => {
        const name = e.target.name; 
        const value = e.target.files[0];
        setValues({
            ...values,
            [name]: value
        });
    }

    const handleSubmit = e => {
        e.preventDefault();
        var form_data = new FormData();
        form_data.append('code', values['code']);
        form_data.append('colour_name', values['colour_name']);
        form_data.append('emotional_name', values['emotional_name']);
        form_data.append('img', values['img']);
        form_data.append('user', localStorage.getItem('username'));
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary'
            },
            body: form_data
        }
        fetch('https://api.gestor.mkr.cl/update_colour/', config)
            .then(response => {
                response.json()
                .then(data => {
                    console.log(data)
                    if(data.ok===200){
                        setIsOpen(true);
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })

    }
    return {values, isOpen, handleChange, handleImage, handleSubmit};
}

export default UseEditColour;
