import React from 'react';
import { Link } from 'react-router-dom';

const Content = ({leakedData, currentPage, dataPerPage}) => {
    const startIndex = (currentPage-1) * dataPerPage;
    const pageData = leakedData.slice(startIndex, startIndex+dataPerPage);
    return (
        <tbody>
            {pageData.map((product, index) => (
                <TableRow key={index} index={index} product={product}/>                
            ))}
        </tbody>
    )
}

const TableRow = ({index, product}) => {
    if(index%2 === 0) {
        return (
            <tr key={index} className="py-3 bg-white py-3">
                <td>{product.ficha_code}</td>
                <td className="pr-10">{product.name}</td>
                <td>{product.brand}</td>
                <td>{product.model}</td>
                <td className="px-3">{product.last_update}</td>
                <td className="">
                    <div className="flex flex-row">
                        <Link to={`ficha/show/${product.id}`}>
                            <div className="flex flex-col pr-8 py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/eye.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Ver</label>
                            </div>
                        </Link>
                        <Link to={`ficha/edit/${product.id}`}>
                            <div className="flex flex-col  py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </Link>
                    </div>
                </td>
            </tr>
        )
    } else {
        return (
            <tr key={index} className="py-3 bg-gray-200 py-3">
                <td className="rounded-l-lg">{product.ficha_code}</td>
                <td className="pr-10">{product.name}</td>
                <td>{product.brand}</td>
                <td>{product.model}</td>
                <td className="px-3">{product.last_update}</td>
                <td className="rounded-r-lg">
                    <div className="flex flex-row">
                        <Link to={`ficha/show/${product.id}`}>
                            <div className="flex flex-col pr-8 py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/eye.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Ver</label>
                            </div>
                        </Link>
                        <Link to={`ficha/edit/${product.id}`}>
                            <div className="flex flex-col  py-1">
                                <img src={process.env.PUBLIC_URL + "/icons/edit.svg"} alt="" className="h-4 mx-auto"/>
                                <label className="text-sm mx-auto font-bold">Editar</label>
                            </div>
                        </Link>
                    </div>
                </td>
            </tr>
        )
    }
    
    
}

export default Content
