import React, {useState} from 'react';
import Login from './components/Login/Login';
import {  
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom';
import Home from './components/Home/Home';
import Categories from './components/Categories/Categories';
import SideBar from './components/SideBar/SideBar';
import EditCategory from './components/Categories/EditCategory';
import ShowCategory from './components/Categories/ShowCategory';
import Products from './components/Products/Products';
import SinFicha from './components/Products/SinFicha';
import EditProduct from './components/Products/EditProduct';
import ShowProduct from './components/Products/ShowProduct';
import Colours from './components/Colours/Colours';
import EditColour from './components/Colours/EditColour';
import Sizes from './components/Sizes/Sizes';
import EditSize from './components/Sizes/EditSize';
import Codes from './components/MkrSku/Codes';
import Alone from './components/Codes/Alone';
import Report from './components/Reports/Report';
import Lists from './components/Reports/Lists';

const App = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    function submitForm() {
        setIsSubmitted(!isSubmitted);
    }
    var token = localStorage.getItem('token')
    return (
        <div>
            {!token ? (
            <Login submitForm={submitForm}/>
            ) : (
                <Router>
                    <div className="flex">
                        <SideBar/>
                        <main className="flex-grow flex flex-col min-h-screen">
                            <Switch>
                                <Route path="/reportes/:type">
                                    <Lists/>
                                </Route>
                                <Route path="/reportes">
                                    <Report/>
                                </Route>
                                <Route path="/codigos/asignar">
                                    <Codes/>
                                </Route>
                                <Route path="/ficha/edit/:code">
                                    <EditProduct/>
                                </Route>
                                <Route path="/ficha/show/:code">
                                    <ShowProduct/>
                                </Route>
                                <Route path="/add_product">
                                    <Home/>
                                </Route>
                                <Route path="/categories/edit/:name">
                                    <EditCategory/>
                                </Route>
                                <Route path="/categories/show/:name">
                                    <ShowCategory/>
                                </Route>
                                <Route path="/categories">
                                    <Categories/>
                                </Route>
                                <Route path="/colours/edit/:colour">
                                    <EditColour/>
                                </Route>
                                <Route path="/colours">
                                    <Colours/>
                                </Route>
                                <Route path="/sizes/edit/:size">
                                    <EditSize/>
                                </Route>
                                <Route path="/sizes">
                                    <Sizes/>
                                </Route>
                                <Route path="/sin_ficha">
                                    <SinFicha/>
                                </Route>
                                <Route path="/codes">
                                    <Alone/>
                                </Route>
                                <Route path="/">
                                    <Products/>
                                </Route>
                            </Switch>
                        </main>
                    </div>
                </Router>
            )}
        </div>
    )
}

export default App;
