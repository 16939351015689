import { useEffect, useState } from 'react'

const UseSizes = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectorLoading, setSelectorLoading] = useState(true);
    const [error, setError] = useState('');
    const [data, setData] = useState([]);
    const [message, setMessage] = useState('')
    const [leakedData, setLeakedData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [values, setValues] = useState({
        size_name: '',
        category:'',
        detail: ''
    });
    const [lengths, setLengths] = useState({
        dataLength: 0,
        leakedLength: 0
    });
    const [filters, setFilters] = useState({
        size_name: '', 
        detail: ''
    });

    useEffect(() => {
        const config = {
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,    
            }
        }
        if(isLoading){
            setIsLoading(false);
            fetch("https://api.gestor.mkr.cl/get_sizes/", config)
                .then(response => {
                    response.json()
                    .then(data => {
                        setData(data);
                        setLeakedData(data);
                        setLengths({
                            dataLength: data.length,
                            leakedLength: data.length
                        });
                    })
                })
            .catch(err => {
                setIsLoading(true);
                console.log(err)
            });
            if(selectorLoading){
                setSelectorLoading(false);
                fetch("https://api.gestor.mkr.cl/to_show_select/", config)
                    .then(response => {
                        response.json()
                        .then(data => {
				setCategories(data)
                        })
                    })
                .catch(err => {
                    setIsLoading(true);
                })
            }
        }
    }, [isLoading]);
    const handleInput = (e) => {
        const {name, value} = e.target;
        setValues({
            ...values,
            [name]: value
        });
        if(name==="size_name") {
            setError('');
        }
    }
    const handleSubmit = e => {
        e.preventDefault();
        if(values.size_name === '') {
            setError('No ha ingresado el nombre');
        } else {
            const config = {
                method: 'POST',
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({values, user:localStorage.getItem('username')})
            }
            fetch('https://api.gestor.mkr.cl/add_size/', config)
                .then(response => {
                    response.json()
                    .then(data => {
                        if(data.status===200) {
                            setIsOpen(true);
                            setIsLoading(true);
                            setMessage('Talla agregada Correctamente');
                        }
                        if(data.status===400) {
                            setIsOpen(true);
                            setMessage(`La Talla ${values.size_name}, ya se encuentra en los registros`);
                        } 
                        
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    
    const handleSelector = (e) => {
        const value =  e.value;
        setValues({
            ...values,
            category:value
        })
    } 

    const handleFilterInput = (e) => {
        const {name, value} = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    } 
    const cleanString = (str) => {
        var clean = str.toUpperCase();
        clean = clean.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        clean = clean.replace(/[^a-zA-Z0-9\s]+/g, "");
        clean = clean.split(" ");
        return clean;
    }
    const compareStrings = (arr1, arr2) => {
        var bool = false
        arr1.forEach((str) => {
            arr2.forEach((str2) => {
                if(str2.includes(str) === true){
                    bool = true;
                }
            });
        });
        return bool;
    }

    const handleFilterSubmit = (e) => {
        const {name, value} = e.target;
        if(e.key==="Enter") {
            var arr = [];
            var cleanValue = cleanString(value);
            if(name==="size_name") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    data.forEach(element  => {
                        var cleanElement = cleanString(element.size_name);
                        if(compareStrings(cleanValue, cleanElement) === true) {
                            arr.push(element);
                        }
                    });
                    setLeakedData(arr);
                    setLengths({
                        ...lengths,
                        leakedLength: arr.length
                    });
                    setFilters({
                        ...filters,
                        [name]: ''
                    });
                }
            }
            if(name==="detail") {
                if(value === '') {
                    setLeakedData(data);
                } else {
                    if(value === '') {
                        setLeakedData(data);
                    } else {
                        data.forEach(element  => {
                            var cleanElement = cleanString(element.detail);
                            if(compareStrings(cleanValue, cleanElement) === true) {
                                arr.push(element);
                            }
                        });
                        setLeakedData(arr);
                        setLengths({
                            ...lengths,
                            leakedLength: arr.length
                        });
                        setFilters({
                            ...filters,
                            [name]: ''
                        });
                    }
                }
            }
            
        } 
    }
    const filtersOff = () => {
        setFilters({
            size_name: '', 
            detail: ''
        });
        setLeakedData(data);
        setLengths({
            ...lengths,
            leakedLength: data.length
        });
    }
    const deleteSize = (name) => {
        const config = {
            method: 'POST',
            headers: {
                Authorization: `Token ${localStorage.getItem('token')}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({user:localStorage.getItem('username')})
        }
        fetch(`https://api.gestor.mkr.cl/delete_size/${name}`, config)
            .then(response => {
                response.json()
                .then(data => {
                    if(data.status===200) {
                        setIsLoading(true);
                    }                        
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    return {values, lengths, filters, categories, leakedData, isOpen, error, message, handleSelector, deleteSize, handleFilterInput, handleFilterSubmit,filtersOff, setIsOpen, handleInput, handleSubmit};
}

export default UseSizes;
